import React, { useContext } from "react";
import config from '../config/index.json';
import { AuthContext } from "../context/UserContext";

import { getDatabase, ref, get } from "firebase/database";
import { useNavigate } from "react-router";

function AdminLogin() {
    const { signIn, loading, setPermission } = useContext(AuthContext);
    const { images } = config;
    const { logo_admin } = images;
    const navigate = useNavigate();

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const form = e.target;
        const email = form.email.value;
        const password = form.password.value;
        signIn(email, password)
            .then((result: any) => {
            form.reset();
            const user = result.user
            const db = getDatabase();
            get(ref(db, "users/" + user.uid)).then((userInfo)=>{
                if (userInfo.val()?.permission=='admin')
                {
                    setPermission('admin');
                    navigate('/user-manager');
                    window.location.reload()
                }
            })
            .catch((error:any)=>{
                console.log('error',error);
            })
            })
            .catch((error: any) => {
            console.error(error);
            form.reset();
            }
        );
    };

    return (
        <div>
            <div className="flex items-center gap-12 text-[#0c2957] pt-2">
                <img className="" src={logo_admin} alt="logo" />
                <h1 className="text-6xl font-bold">User Manager</h1>
            </div>
            <div className="w-full flex justify-center mt-[200px]">
                <form onSubmit={handleSubmit} action="" className="w-[40%] flex flex-col gap-6">
                    <input id="email" name="email" className="h-[60px] bg-white px-4 border border-black" type="email" placeholder="E-mail" />
                    <input id="password" name="password" className="h-[60px] bg-white px-4 border border-black" type="password" placeholder="Password" />
                    <button className="h-[60px] bg-[#0c59cf]" type="submit">ACCEDI</button>
                </form>
            </div>
        </div>
    );
}

export default AdminLogin;

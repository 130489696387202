import React from 'react'

function MobileMenu() {
  return (
    <div className='bg-[#e0f9fd] pt-16 px-[50px] pb-[100px] md:hidden'>
        <h1 className=' mb-12 text-xl'>Scopri Italy HLG</h1>
        <div className='flex flex-col gap-4 text-[#0c2957] text-2xl font-bold'>
            <a href='/chi-siamo' className='hover:text-[#00a695]'>Chi siamo</a>
            <a href="/news-list" className='hover:text-[#00a695]'>News ed eventi</a>
            <a href='/network-internazionale' className='hover:text-[#00a695]'>Network</a>
            <a href='/bd-academy' className='hover:text-[#00a695]'>Educazione</a>
            <a href="/login" className='text-[#00a695]'>Accedi/Registrati</a>
        </div>
    </div>
  )
}

export default MobileMenu
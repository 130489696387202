import React, { useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import config from '../config/index.json';
import { Link } from 'react-router-dom';
import MailchimpForm from './MailchimpFormContainer';

function Footer() {

    const { images } = config;
    const { linkedin, flicker, logo, dot } = images;
    const [showFooter, setShowFooter] = useState(false);

    useEffect(() => {
        AOS.init({ duration: 2000 });
        const timer = setTimeout(()=>{
            setShowFooter(true);
        },1000);
    }, []);

    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleClick = (index: number) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div>
            { showFooter &&
            <footer className='bg-blackBlue pt-16 md:pt-32 pb-12 ps-8 pe-16 md:px-16 lazy-show-foote'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-12'>
                    <div className='text-white text-3xl font-bold md:pr-[20%] leading-normal'>
                        <div className='mb-6 text-2xl font-semibold'>
                            Sviluppa con Italy HLG relazioni, alleanze strategiche e partnership nell'industria dell'healthcare
                        </div>
                        <MailchimpForm/>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 text-white font-semibold leading-normal text-lg'>
                        <div className='border-t border-white/20 py-6 pe-[30%] w-[80%] md:w-full'>
                            <span className='text-[#1b8176] mb-2 block'>sede italiana</span>Via Fabio Filzi, 19<br />20124 Milano - Italy<br />p.iva: 12648300155
                        </div>
                        <div className='border-t border-white/20 py-6 pe-[30%] w-[80%] md:w-full'>
                            <span className='text-green mb-2 block'>Contatti</span>
                            <a href='mailto:info@italyhlg.it'>info@italyhlg.it</a>
                        </div>
                        <div className='border-t border-white/20 py-6 w-[80%] md:w-full'>
                            <span className='mb-2 block'>Seguici su</span>
                            <div className='flex gap-8'>
                                <Link
                                    to='https://www.linkedin.com/company/italy-hlg'
                                    className=''><img src={linkedin} /></Link>
                                <Link to='https://www.flickr.com/photos/157749393@N04/'>
                                    <img src={flicker} alt="" className='h-[37px]'/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-12 pt-24 md:pt-36 text-white leading-normal'>
                    <div>
                        <img src={logo} className='w-36' />
                    </div>
                    <div className='py-6 md:hidden'>
                        © 2023 - Italy HLG
                    </div>
                    <div className='grid grid-cols-2 gap-16 text-white leading-normal hidden md:grid'>
                        <div className='py-6'>
                            © 2023 - Italy HLG
                        </div>
                        <div className='py-6 flex'>
                            <Link
                                to='/privacy-policy'
                                className='hover:underline'>Privacy policy</Link>
                            <img src={dot} className='w-1 mx-4' />
                            <Link
                                to='/cookie-policy'
                                className='hover:underline'>Cookie policy</Link>
                        </div>
                    </div>
                </div>
                <div className='py-6 flex text-white mt-16 md:hidden'>
                    <Link
                        to='/privacy-policy'
                        className='hover:underline'>Privacy policy</Link>
                    <img src={dot} className='w-1 mx-4' />
                    <Link
                        to='cookie-policy'
                        className='hover:underline'>Cookie policy</Link>
                </div>
            </footer>
        }
        </div>
    )
}

export default Footer
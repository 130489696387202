import React, { useContext, useEffect, useState } from 'react';
import config from '../config/index.json';
import { AuthContext } from '../context/UserContext';
import { useNavigate } from "react-router";
import { ref, get, getDatabase } from "firebase/database";

function Header() {

    const { images } = config;
    const { logo } = images;
    const { user, loading } = useContext(AuthContext)
    const [ loginState, setLoginState ] = useState(false);

    useEffect(()=>{
        if (!loading) {
            if (user) {
                const db = getDatabase();
                get(ref(db, "users/" + user?.uid)).then((userInfo)=>{
                    const verified = userInfo.val()?.verified;
                    if (verified==undefined) {
                        setLoginState(false)
                    } else {
                        setLoginState(true)
                    }
                })
                .catch((error)=>{
                    setLoginState(false)
                });
            } else {
                setLoginState(false)
            }
        }
    },[])

    useEffect(() => {
        const overlay = document.querySelector('.overlay');
        const navItems = document.querySelectorAll('.navItem');

        if (overlay) {
            navItems.forEach((navItem) => {
                navItem.addEventListener('mouseover', () => {
                    const dataItem = navItem.getAttribute('data-item') ?? '';
                    overlay.setAttribute('data-item', dataItem);
                });
                navItem.addEventListener('mouseout', () => {
                    overlay.removeAttribute('data-item');
                });
            });
        }

        return () => {
            navItems.forEach((navItem) => {
                navItem.removeEventListener('mouseover', () => {});
                navItem.removeEventListener('mouseout', () => {});
            });
        };
    }, []);

    return (
        <div id="header" className='absolute w-full top-0 z-20 pt-4 md:pt-16 pb-4 px-4 md:px-16 hidden md:block'>
            <div className='headWrap flex justify-center md:justify-between flex-col-reverse	md:flex-row'>
                <div className='flex items-center'>
                    <a href="/">
                        <img src={logo} className="mt-16 md:my-4 w-40 block mx-auto" alt="logo" />
                    </a>
                </div>
                <div className='flex items-center'>
                    <ul className='mainNav'>
                        <li className='navItem' style={{paddingRight:0}} data-item='chisiamo'>
                            <a href='/chi-siamo'>Chi siamo</a>
                            <ul className='subNav'>
                                <li><a href='/chi-siamo'>Chi siamo</a></li>
                                <li><a href='/board'>Board</a></li>
                                <li><a href='/sponsorship'>Sponsorship</a></li>
                            </ul>
                        </li>
                        <li className='navItem' style={{paddingRight:0}} data-item='news'>
                            <a href='/news-list'>News ed eventi</a>
                            <ul className='subNav'>
                                <li><a href='/news-list'>News ed eventi</a></li>
                                <li><a href='/newsletter'>Archivio newsletter</a></li>
                            </ul>
                        </li>
                        <li className='navItem' style={{paddingRight:0}} data-item='network'>
                            <a href='/network-internazionale'>Network</a>
                            <ul className='subNav'>
                                <li><a href='/network-internazionale'>Network internazionale</a></li>
                                <li><a href='/ipls'>IPLS</a></li>
                            </ul>
                        </li>
                        <li className='navItem' data-item='edu'>
                            <a href='/bd-academy'>Educazione</a>
                            <ul className='subNav'>
                                <li><a href='/bd-academy'>BD Academy</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className='flex items-center'>
                {
                    (!loginState) ?
                        <a
                            key="login"
                            href="/login"
                            className="hidden md:inline text-base font-bold ml-4 cursor-pointer transition hover:border-b text-[#00C3AF]"
                            >
                            Accedi / Iscriviti
                        </a> : 
                        <a
                            href='/profilo'
                            key="documenti"
                            className="hidden md:inline text-white text-base font-medium ml-4 cursor-pointer transition hover:border-b"
                            >
                            Area riservata
                        </a>
                }
                </div>
            </div>
            <div className='overlay'></div>
        </div>
    )
}

export default Header
import React, { useContext, useEffect, useState } from 'react';
import config from '../config/index.json';
import { AuthContext } from '../context/UserContext';

function MobileHeader(props:any) {
    const { home } = props;
    const { images } = config;
    const { logo, expandMenuIcon } = images;
    const {user } = useContext(AuthContext)
    const [ showMenu, setShowMenu ] = useState(false);

    const collapseMenu = () => {
        setShowMenu(false)
    }

    const expandMenu = () => {
        setShowMenu(true);
    }

    useEffect(() => {
        const overlay = document.querySelector('.overlay');
        const navItems = document.querySelectorAll('.navItem');

        if (overlay) {
            navItems.forEach((navItem) => {
                navItem.addEventListener('mouseover', () => {
                    const dataItem = navItem.getAttribute('data-item') ?? '';
                    overlay.setAttribute('data-item', dataItem);
                });
                navItem.addEventListener('mouseout', () => {
                    overlay.removeAttribute('data-item');
                });
            });
        }

        return () => {
            navItems.forEach((navItem) => {
                navItem.removeEventListener('mouseover', () => {});
                navItem.removeEventListener('mouseout', () => {});
            });
        };
    }, []);

    return (
        <div id="header" className={`absolute w-full h-screen top-0 ${showMenu ? 'z-[101]' : 'z-1' } pt-4 md:pt-16 pb-4 px-4 md:px-16 md:hidden ${showMenu ? 'bg-[#020f25] opacity-90':'bg-transparent'}`}>
            <div className='headWrap flex justify-center md:justify-between	md:flex-row'>
                <div className='flex w-full justify-between items-center pt-4 px-8'>
                    <a href="/">
                        <img src={logo} className={`md:mt-16 md:my-4 w-32 md:w-40 block ${showMenu ? 'opacity-50' : 'opacity-100' }`} alt="logo" />
                    </a>
                    {
                        showMenu ?
                        <div onClick={collapseMenu} className='text-white text-5xl font-normal hover:cursor-pointer'>
                            &#10799;
                        </div>
                        :
                        <div onClick={expandMenu}>
                            <img src={expandMenuIcon} className="w-[2rem] md:mt-16 md:my-4 md:w-32 block mx-auto hover:cursor-pointer" alt="" />
                        </div>
                    }
                </div>
            </div>
            { showMenu ?
                <div className='flex w-full h-full justify-center '>
                    <div className='flex flex-col w-fit-content justify-center items-centers gap-4 mb-8 text-white text-3xl font-semibold'>
                        <a href='/chi-siamo' className='hover:text-[#00a695]'>Chi siamo</a>
                        <a href="/news-list" className='hover:text-[#00a695]'>News ed eventi</a>
                        <a href='/network-internazionale' className='hover:text-[#00a695]'>Network</a>
                        <a href='/bd-academy' className='hover:text-[#00a695]'>Educazione</a>
                        <a href="/login" className='text-[#00a695] hover:underline'>Accedi/Registrati</a>
                    </div>
                </div>
                :
                home &&
                <div className='text-white text-4xl font-bold mt-[100px] leading-[50px] px-8 absolute z-10'>
                    Supportiamo lo sviluppo di relazioni, alleanze strategiche e partnership per l'healthcare
                </div>
            }
            <div className='overlay'></div>
        </div>
    )
}

export default MobileHeader
import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/UserContext";

import config from '../config/index.json';
import ReactPaginate from "react-paginate";
import ResponsiveHeader from "../components/ResponsiveHeader";
import HeroBackgroundImage from "../images/hero.jpg";
import ReservedSideMenu from "../components/ReservedSideMenu";
// desktop
const itemsPerPage = 25;
// mobile display iteems
const displayPerLoad = 3;

function AssociatesList() {
  const { images } = config
  const { logo, useravatarEmpty, linkedinBlue } = images
  const { logOut, userList, getUsers } = useContext(AuthContext);
  const [ currentPage, setCurrentPage ] = useState(0);
  const [ displayedNews, setDisplayedNews ] = useState(displayPerLoad);
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLoadMore = () => {
    const numberOfUsers = userList?.length ?? 0;
    if (displayedNews+displayPerLoad>numberOfUsers) {
      setDisplayedNews(numberOfUsers);
    } else {
      setDisplayedNews(displayedNews+displayPerLoad);
    }
  }

  const handlePageClick = (event:any) => {
    setCurrentPage(event.selected);
  }

  useEffect(()=>{
    getUsers()
  },[])

  const onClickLogout = () => {
    logOut();
    navigate('/')
  }

  const pageCount = Math.ceil(userList?.length/itemsPerPage);
  const startIndex = itemsPerPage * currentPage;
  const endIndex = startIndex + itemsPerPage;
  const currentUserList = userList.slice(startIndex, endIndex);
  const username = `${userInfo?.name ?? ''} ${userInfo?.surname ?? ''}`;
  return (
    <div className="bg-[#effbff]">
      {/* <section
        id="hero"
        className="bg-hero brightness-100 bg-no-repeat w-3/5 bg-center bg-cover pt-80 pb-6 h-[40vh]"
      >
        <div
          id="header"
          className="absolute w-full top-0 z-20 pt-4 md:pt-16 pb-4 px-4 md:px-16"
        >
          <div className="flex justify-center md:justify-between flex-col-reverse	md:flex-row">
            <div className="flex items-left md:items-center">
              <img
                src={logo}
                className="mt-16 md:my-4 w-40 block mr-auto ml-0 md:mx-auto hover:cursor-pointer"
                alt="logo"
                onClick={handleClickLogo}
              />
            </div>
          </div>
        </div>
      </section> */}
      {/* <SimpleHeader/> */}
      <ResponsiveHeader backgroundImage={HeroBackgroundImage} title="Area riservata" />
      <section>
        <div className="pb-[200px]">
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className="hidden md:block col-span-2 pt-[100px] pl-[60px] md:pr-[20px] lg:pr-[60px]">
                <>
                  {username && <h1 className="text-lg font-bold mb-2">{username}</h1>}
                  <hr className="color-[#00a695] border border-[#00a695] w-4/5" />
                  <a href="/documenti">
                    <div className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a594] hover:underline">
                      Archivio documenti riservati
                    </div>
                  </a>
                  <div className="text-lg text-[#242424]  mt-2 font-[800]">
                    Elenco associati
                  </div>
                  <a href="/profilo">
                    <div className="text-lg text-[#242424]  mt-2 hover:text-[#00a594] hover:underline">
                        Il mio profilo
                    </div>
                  </a>
                  <div onClick={()=>onClickLogout()} className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a594] hover:underline hover:cursor-pointer">
                    Esci
                  </div>
                  <hr className="color-[#00a695] border border-[#00a695] w-4/5 mt-80" />
                  <div className="text-lg text-[#242424] font-semibold mt-2">
                    Hai bisogno di aiuto?
                  </div>
                  <a href="mailto:info@italyhlg.it" className="text-lg font-semibold text-[#00a695] mt-2 underline">
                    info@italyhlg.it
                  </a>
                </>
            </div>

            <div id="" className="col-span-3 z-10 md:mt-[-120px]">
              <div id="form" className="z-50 bg-[#effbff] pt-4 px-14 md:p-24 ">
                <div className="text-5xl text-[#0c2957] font-[700]">
                  <h1 className="mb-6 hidden md:block">Area Riservata</h1>
                  <p className="my-10 font-semibold text-lg text-[#242424]">
                    In questa sezione sono disponibili la lista degli iscritti ad Italy HLG, i contributi dei relatori che partecipano ad eventi e webinar da noi promossi, le presentazioni delle Assemblee, la collezione delle uscite del Business Development & Licensing Journal.
                  </p>
                  <ReservedSideMenu selectedMenuIndex={1} />
                  <h1 className="text-xl mb-16 mt-16 md:mt-0">Elenco associati Italy HLG</h1>
                </div>
                {
                    currentUserList?.map((user:any)=>{
                        const role = user?.role ?? '';
                        const company = user?.agency ?? '';
                        const company_city = user?.company_city ?? '';
                        const user_role = (role && company) ? `${role}, ${company} - ${company_city}` : `${role}${company}`;
                        return (
                        <div className="hidden md:flex flex-col md:flex-row gap-2 mb-8 pb-4 border-b-2 border-gray-500">
                            {user?.portfolio ?
                              <img className="w-[120px]" src={user?.portfolio} alt="" />
                              :
                              <img className="w-[120px]" src={useravatarEmpty} alt="" />
                            }

                            <div className="flex flex-col justify-end items center">
                                <h1 className="font-bold text-xl">{user?.name+' '+user?.surname}</h1>
                                <h1 className="my-2 h-[24px] truncate text-clip">{user_role}</h1>
                                <div className="flex w-full mt-2 mb-4 md:mb-0 md:mt-0 gap-6 justify-start items-end">
                                    {
                                        user?.linkedin ?
                                        <a href={user.linkedin}>
                                          <img className="max-w-[20px] w-[20px] h-[20px]" src={linkedinBlue} alt="" />
                                        </a>
                                        :
                                        <img className="max-w-[20px] w-[20px] h-[20px] opacity-50" src={linkedinBlue} alt="" />
                                    }
                                    <div className="flex flex-col items-start justify-end md:block md:mt-4">
                                      <a className="hover:underline block" href={`mailto:${user?.email}`}>{user?.email}</a>
                                      <a className="hover:underline block" href={`tel:${user?.telephone}`}>{user?.telephone}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    })
                }
                {
                    userList?.slice(0, displayedNews).map((user:any)=>{
                        const role = user?.role ?? '';
                        const company = user?.agency ?? '';
                        const company_city = user?.company_city ?? '';
                        const user_role = (role && company) ? `${role}, ${company}-${company_city}` : `${role}${company}`;
                        return (
                        <div className="flex flex-col md:hidden gap-2 mb-8 pb-4 border-b-2 border-gray-500">
                            <img className="w-[120px]" src={user?.portfolio ?? useravatarEmpty} alt="" />
                            <div className="flex flex-col justify-end items center">
                                <h1 className="font-bold text-xl">{user?.name+' '+user?.surname}</h1>
                                <h1 className="my-2 h-[24px] truncate text-clip">{user_role}</h1>
                                <div className="flex w-full mt-2 mb-4 md:mb-0 md:mt-0 gap-6 justify-start">
                                    {
                                        user?.linkedin ?
                                        <a href={user.linkedin}>
                                          <img className="max-w-[20px] w-[20px] h-[20px]" src={linkedinBlue} alt="" />
                                        </a>
                                        :
                                        <img className="max-w-[20px] w-[20px] h-[20px] opacity-50" src={linkedinBlue} alt="" />
                                    }
                                    <div className="flex flex-col items-start md:block md:mt-4">
                                      <a className="hover:underline" href={`mailto:${user?.email}`}>{user?.email}</a>
                                      <a className="hover:underline" href={`tel:${user?.telephone}`}>{user?.telephone}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    })
                }
                <div onClick={handleLoadMore} className="bg-[#b0d8ff] hover:cursor-pointer py-4 text-white md:hidden mt-16 text-center">
                  LOAD MORE
                </div>
                <ReactPaginate
                    initialPage={0}
                    breakLabel="..."
                    containerClassName="hidden md:flex gap-4 justify-center items-center text-xl font-semibold text-gray-600"
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={itemsPerPage}
                    pageCount={pageCount}
                    previousLabel="<"
                    pageClassName="flex hover:font-bold w-[25px] h-[30px] rounded-sm text-center"
                    pageLinkClassName="w-full"
                    previousClassName="hover:bg-gray-400 w-[25px] text-center"
                    nextClassName="hover:bg-gray-400 w-[25px] text-center"
                    previousLinkClassName="w-full"
                    nextLinkClassName="w-full"
                    activeClassName="bg-[#15818d] text-white"
                    renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default AssociatesList;

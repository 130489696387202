import React, { useContext, useEffect, useState } from 'react'
import config from "../config/index.json"
import { AuthContext } from '../context/UserContext';
import { useNavigate } from 'react-router';
import { getDatabase, ref, update } from 'firebase/database';
import emailjs from '@emailjs/browser'
// vadim account for emailjs
const emailjs_service_id = "service_z64n7l2";
const emailjs_template_id  = "template_ttkiv7k";
const emailjs_public_key = "ypaxiBIEvH9DhF2uO"
const company_link = "https://italyhlg.it/grildu249weriw239dsj771"
const company_name = "HLG"

function UserManager() {
    const { images } = config;
    const { logo_admin, verifiedIcon, unverifiedIcon } = images;
    const { user, permission, logOut, getUsers, userList } = useContext(AuthContext);
    const [ users, setUsers ] = useState<any>([]);
    const [ selectedIndex, setSelectedIndex ] = useState(-1);
    const [ rowStatus, setRowStatus ] = useState("");
    const [ searchKey, setSearchKey ] = useState("");
    const [ hoveredIndex, setHoveredIndex ] = useState(-1);

    const navigate = useNavigate();

    const logout = () => {
        logOut();
        navigate('/super-manager');
    }

    const handleMouseEnter = (index:any) => {
        setHoveredIndex(index)
    }

    const handleMouseLeave = (index:any) => {
        setHoveredIndex(-1);
    }

    const handleSearch = (e:any) => {
        e.preventDefault();
        const form = e.target;
        const { search } = form;
        setSearchKey(search.value);
    }

    const handleClickEdit = (uid:any) => {
        navigate(`/super-manager/edit/${uid}`);
    }

    const handleClickEnable = (index:number) => {
        setSelectedIndex(index);
        setRowStatus("enable");
    }

    const handleConfirmEnable = (user:any) => {
        const db = getDatabase();
        const nodeRef = ref(db, `users/${user.uid}`);
        const { uid, ...oldData } = user;
        const updatedUser = {
            ...oldData,
            verified: true
        }
        // get the current Date
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let currentDate = `${month}-${day}-${year}`;
        let hr = date.getHours();
        let mm = date.getMinutes();
        let sec = date.getSeconds();
        let currentTime = `${hr}:${mm}:${sec}`;
        update(nodeRef, updatedUser)
            .then(()=>{
                emailjs.send(emailjs_service_id, emailjs_template_id,{
                    from_name: company_name,
                    message: `${company_link}?status=verify&email=${user?.email}&id=${user?.uid}`,
                    date: `${currentDate} ${currentTime}`,
                    to_email: user?.email,
                    }).then(()=>{

                    }).catch((error:any)=>{
                        console.error(error)
                    })
            })
            .catch((error) => {
                console.error("Error updating node: ", error);
            })
        setSelectedIndex(-1);
        getUsers();
    }

    const handleCancelEnable = () => {
        setSelectedIndex(-1);
        setRowStatus("")
    }

    useEffect(()=>{
        getUsers();
    },[])

    useEffect(()=>{        
        emailjs.init(emailjs_public_key);
        if (searchKey!="" && searchKey) 
        {
            const users = userList.filter((user:any)=>{return user?.email?.includes(searchKey) || user?.name?.includes(searchKey) || user?.surname?.includes(searchKey)});
            setUsers(users);
        } else {
            setUsers(userList);
        }
    },[searchKey, permission, userList])

    const sortedUsers = users.sort((a:any,b:any)=>{
        let partialDates = a?.createdAt?.split('-') || [];
        const yy = parseInt(partialDates?.[2]) ?? 0;
        const mm = parseInt(partialDates?.[0]) ?? 0;
        const dd = parseInt(partialDates?.[1]) ?? 0;
        const unionDate = yy*10000 + mm*100 + dd;
        let partialDates1 = b?.createdAt?.split('-');
        const yy1 = parseInt(partialDates1?.[2]) ?? 0;
        const mm1 = parseInt(partialDates1?.[0]) ?? 0;
        const dd1 = parseInt(partialDates1?.[1]) ?? 0;
        const unionDate1 = yy1*10000 + mm1*100 + dd1;
        return unionDate1-unionDate;
    })

    return (
        <div className='overflow-auto'>
            <div className="flex items-end gap-12 text-[#0c2957] pt-2">
                <img className="" src={logo_admin} width={150} alt="logo" />
                <div className='flex flex-col'>
                    <h1 className="text-4xl font-bold mb-6">User Manager</h1>
                    <h3>Welcome <span className='underline hover:cursor-pointer' onClick={logout}>Log out</span></h3>
                </div>
            </div>
            <div className='flex justify-center w-full'>
                <form className='flex my-20 w-[50%] outline outline-1' action="" onSubmit={handleSearch}>
                    <input id="search" name="search" className="h-[50px] bg-white px-4 border-none outline-none border-black w-[95%] " placeholder="Search" />
                    <button className='w-[30px] text-[20px]' type='submit'>&#x1F50E;&#xFE0E;</button>
                </form>
            </div>
            <table className="min-w-full text-left text-sm font-light overflow-y-scroll">
                <tbody>
                    {
                        user && permission =='admin' &&
                        sortedUsers.map((user:any,index:number)=>{
                            return  (
                                <tr onMouseEnter={()=>handleMouseEnter(index)} onMouseLeave={()=>handleMouseLeave(index)} className={`h-[80px] border-b text-black ${index&0x01 ? 'bg-white' : 'bg-[#c9f1fd]'} hover:bg-[#FEFAC0]`}>
                                    <td className="whitespace-nowrap px-6 py-4 font-medium">{index}</td>
                                    <td className="whitespace-nowrap px-6 py-4">{`${user?.name} ${user.surname}`}</td>
                                    <td className="whitespace-nowrap px-6 py-4">{user?.email}</td>
                                    <td className="whitespace-nowrap px-6 py-4">{user?.createdAt}</td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                    {
                                        selectedIndex == index && rowStatus == "enable" ?
                                            <div className='flex items-center justify-end w-full h-full'>
                                                Do you confirm to enable the user? <span onClick={()=>handleConfirmEnable(user)} className='underline px-2 hover:font-semibold hover:cursor-pointer w-[40px]'>Yes</span> <span onClick={handleCancelEnable} className='underline hover:font-semibold hover:cursor-pointer w-[40px]'>Cancel</span>
                                            </div>
                                        :
                                            <div className={`flex gap-4 justify-end ${hoveredIndex!==index ? 'invisible' : 'visible'}`}>
                                                <button onClick={()=>handleClickEdit(user.uid)} className='bg-[#0c59cf] text-white w-[100px] py-3 font-semibold'>Edit</button>
                                                {!user?.verified && <button onClick={()=>handleClickEnable(index)} className='bg-[#ff3823] text-white w-[100px] py-3 font-semibold'>Enable</button>}
                                            </div>
                                    }
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                        {user?.verified ?
                                            <img src={verifiedIcon} width={30} alt="aaa" />
                                            :
                                            <img src={unverifiedIcon} width={30} alt="bbb" />
                                        }
                                        {/* {user?.verified ? 'verified':'not verified'} */}
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {/* <tr className="border-b bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700">
                        <td className="whitespace-nowrap px-6 py-4 font-medium">1</td>
                        <td className="whitespace-nowrap px-6 py-4">Mark</td>
                        <td className="whitespace-nowrap px-6 py-4">Otto</td>
                        <td className="whitespace-nowrap px-6 py-4">@mdo</td>
                    </tr>
                    <tr className="border-b bg-white dark:border-neutral-500 dark:bg-neutral-600">
                        <td className="whitespace-nowrap px-6 py-4 font-medium">2</td>
                        <td className="whitespace-nowrap px-6 py-4">Jacob</td>
                        <td className="whitespace-nowrap px-6 py-4">Thornton</td>
                        <td className="whitespace-nowrap px-6 py-4">@fat</td>
                    </tr> */}
                </tbody>
            </table>
        </div>
  )
}

export default UserManager
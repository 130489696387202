import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import config from "../config/index.json";
import { AuthContext } from "../context/UserContext";
import DocumentCategory from "../components/DocumentCategory";
import Header from "../components/Header";

function PrivateDocument() {
  const CONTENTFUL_SPACE_ID = "qitjy5hyl5up";
  const CONTENTFUL_ACCESS_TOKEN = "0PL0qCiNnT4GhSRGoDG8E69TrtEvJwipPHfutAMhECU";
  const { user, userInfo } = useContext(AuthContext);
  const { images } = config;
  const { pdf } = images;

  const [documentCategories, setDocumentCategories] = useState<any[]>()
  const [docs, setDocs] = useState<any>()
  const [docCat, setDocCat] = useState("total");
  const [docId, setDocId] = useState("")

  const contentfulEndpoint_doc = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=document`;
  const contentfulEndpoint_category = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=documentCategory`;

  const handleCategory = (cat:any) => {
    setDocId("")
    setDocCat(cat);
  }

  const getDescription = (desc:any) => {
    let str=""
    desc?.forEach((element:any) => {
      element?.content.forEach((el:any) => {
        str=str+el.value
      });
    });
    return str
  }

  useEffect(() => {
    (async () => {
      await fetch(contentfulEndpoint_category)
        .then((res) => {
          return res.json();
        })
        .then((res: any) => {
            setDocumentCategories(res.items)
        })
        .catch((err) => {});
    })();
    (async () => {
        await fetch(contentfulEndpoint_doc)
          .then((res) => {
            return res.json();
          })
          .then((res: any) => {
              setDocs(res)
          })
          .catch((err) => {});
      })();
  }, []);
  const username = `${userInfo?.name ?? ''} ${userInfo?.surname ?? ''}`
  return (
    <>
      <section id='hero' className='bg-hero bg-no-repeat bg-center bg-cover relative h-auto'>
          <video autoPlay loop muted className=''> 
              <source src={require('../videos/home.mp4')} type='video/mp4'/>
          </video>
      </section>
      <Header></Header>
      {
        user &&
        <section className="bg-[#effbff]">
            <div className="pb-[200px] ">
            <div className="grid grid-cols-1 md:grid-cols-5 ">
                <div className="col-span-2 pt-[100px] pl-[60px] md:pr-[20px] lg:pr-[60px]">
                <h1 className="font-bold text-xl pb-2">{username}</h1>
                <hr className="color-[#00a695] border border-[#00a695] w-4/5" />
                <div className="my-[20px] text-xl font-[500]">
                    Archivio documenti
                </div>
                <a className="block underline text-xl mb-4 font-semibold" href="">
                    ll mio profilo
                </a>
                <a className="mt-[40px] text-xl underline font-semibold">
                    Esci
                </a>
                </div>
                <div className="col-span-3 z-50  mt-[-120px]">
                <div className="p-24 bg-[#effbff]">
                    <div className="text-5xl text-[#0c2957] font-[700]">
                    <h1 className="mb-6">Area Riservata</h1>
                    </div>
                    <p className="mt-6 font-[600]">
                        Una bella introduzione qui ipsum dolor sit amet, consectetur adipiscing elit. Duis euismod euismod sem, posuere tincidunt sapien maximus sed. Duis erat risus, lacinia a neque a,,,
                    </p>
                    <h1 className="mt-6 text-2xl font-[600] text-[#0c2957]">
                        Archivio documenti riservati
                    </h1>
                    {
                      documentCategories && documentCategories.length>0 && docs &&
                      <div className="pt-10 flex flex-wrap w-full md:w-3/4 gap-6">
                        <DocumentCategory onSelect={()=>handleCategory("total")} category="Tutti" color="1c5589" numbers={docs.items.length} selected={docCat=='total'}/>
                        {
                          documentCategories?.sort(function(a,b){return a.fields.name.localeCompare(b.fields.name)}).map((cat)=>{return <DocumentCategory onSelect={()=>handleCategory(cat.sys.id)} category={cat.fields.name} color={cat.fields.color} numbers={docs.items.filter((item:any)=>{ return  item.fields?.category[0].sys.id==cat.sys.id}).length} selected={docCat==cat.sys.id}/>})
                        }
                      </div>
                    }
                    <h1 className="text-2xl pt-10 font-[600] text-[#788ca6]">
                      Tutti i documenti
                    </h1>
                    {
                      docs &&
                        docs.items.filter((item:any)=>{return docCat=='total' || docCat==item.fields?.category[0].sys.id}).map((doc:any) => {
                          return (
                            <div className="flex hover:border hover:border-blue-500 w-2/3 border-2 rounded-xl p-8 bg-[#f6fdff] my-8 " onClick={()=>{if(docId) setDocId(''); else setDocId(doc.sys.id);}} >
                              <img srcSet={pdf} className="w-[60px] h-[60px]" alt="123" />
                              <div className="w-full px-4">
                                <div className="w-full flex justify-between">
                                  <div className="text-lg text-gray-400">
                                    {doc.fields.date}
                                  </div>
                                  <div className="text-xl">
                                    {doc.fields.fileSize}
                                  </div>
                                </div>
                                <div className="text-xl text-[#0c2957] font-[800] ">
                                  {doc.fields.name}
                                </div>
                                {
                                  docId==doc.sys.id &&
                                  <div className="py-6">
                                    <p className="font-semibold">{getDescription(doc.fields.description.content)}</p>
                                    <a target="_blank" href={docs.includes.Asset.filter((asset:any)=>{return asset.sys.id==doc.fields.file.sys.id})[0].fields.file.url} download className="px-16 py-10 mt-8 flex justify-center items-center h-[50px] text-white bg-[#00a695]"><div>Download</div></a>
                                  </div>
                                }
                              </div>      
                            </div>
                          )
                        })
                    }
                </div>
                </div>
            </div>
            </div>
        </section>
        }
      <Footer></Footer>
    </>
  );
}

export default PrivateDocument;

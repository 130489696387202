import React, { useEffect, useState, memo } from "react";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import config from "../config/index.json";
import Header from "../components/Header";
import Footer from "../components/Footer";

import Collegio from "../components/Collegios";
import Consiglio from "../components/Consiglios";
import MobileHeader from "../components/MobileHeader";
interface ContentfulParagraph {
  content: ContentfulText[];
  nodeType: "paragraph";
}

interface ContentfulText {
  value: string;
  nodeType: "text";
}

interface ContentfulBody {
  content: (ContentfulParagraph | ContentfulText)[];
  nodeType: "document";
}

interface PicConDetails {
  sys: {
    type: string;
    linkType: string;
    id: string;
  };
  fields: {
    file: {
      url: string;
    };
  };
}

interface Page {
  fields: {
    title: string;
    summary: ContentfulBody;
    body: ContentfulBody;
    backgroundColor: string;
    primaryColor: string;
    consiglioDirettivo: ConMember[];
    suggestedPage: {
      sys: {
        id: string;
      };
    };
  };
}

interface ConMember {
  sys: {
    type: string;
    linkType: string;
    id: string;
  };
  fields: {
    fullName: string;
    company: string;
    role: string;
    linkedinProfile: string;
    profilePicture: {
      sys: {
        id: string;
      };
    };
  };
}

export const slugify = (str: string): string => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

const Board = memo(() => {
  const CONTENTFUL_SPACE_ID = "qitjy5hyl5up";
  const CONTENTFUL_ACCESS_TOKEN = "0PL0qCiNnT4GhSRGoDG8E69TrtEvJwipPHfutAMhECU";

  const { images } = config;
  const { arrow2, inIcon } = images;

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Przewijanie do góry strony
  };

  const [selectedPage, setSelectedPage] = useState<Page | undefined>(undefined);

  const [suggestedPageTitle, setSuggestedPageTitle] = useState<
    string | undefined
  >(undefined);

  const [collegio, setCollegio] = useState<any[]>([]);
  const [consiglio, setConsiglio] = useState<any[]>([]);

  const contentfulEndpoint = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=board`;

  useEffect(() => {
    (async () => {
      await fetch(contentfulEndpoint)
        .then((res) => {
          return res.json();
        })
        .then((res: any) => {
          setSelectedPage(res.items[0]);
          let mems = res.includes.Entry.filter((entry: any) => {
            return entry.sys.contentType.sys.id == "people";
          });

          let collegio_entry: any[] = [];

          res.items[0].fields.collegioDeiRevisori.forEach((el: any) => {
            let collegio_item: any = res.includes.Entry.filter((en: any) => {
              return en.sys.id == el.sys.id;
            })[0];

            let collegio_asset = res.includes.Asset.filter((asset: any) => {
              return asset.sys.id == collegio_item.fields.profilePicture.sys.id;
            })[0];

            collegio_item.media_url = collegio_asset.fields.file.url;
            collegio_entry = [...collegio_entry, collegio_item];
          });

          setCollegio(collegio_entry);
          let consiglio_entry: any[] = [];
          res.items[0].fields.consiglioDirettivo.forEach((el: any) => {
            let consiglio_item: any = res.includes.Entry.filter((en: any) => {
              return en.sys.id == el.sys.id;
            })[0];
            let consiglio_asset = res.includes.Asset.filter((asset: any) => {
              return (
                asset.sys.id == consiglio_item.fields.profilePicture.sys.id
              );
            })[0];
            consiglio_item.media_url = consiglio_asset.fields.file.url;
            consiglio_entry = [...consiglio_entry, consiglio_item];
          });

          setConsiglio(consiglio_entry);
          const suggestedPageId = res.items[0].fields.suggestedPage.sys.id;
          (async () => {
            await fetch(
              `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries/${suggestedPageId}?access_token=${CONTENTFUL_ACCESS_TOKEN}`
            )
              .then((res) => {
                return res.json();
              })
              .then((res: any) => {
                setSuggestedPageTitle(res.fields.title);
              });
          })();
        })
        .catch((err) => {});
    })();
  }, []);

  if (!selectedPage) {
    return <div>Loading...</div>;
  }

  const { summary, body, backgroundColor, primaryColor } = selectedPage.fields;

  const slugify = (str: string): string => {
    return str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  };
  const suggestedSlug = suggestedPageTitle ? slugify(suggestedPageTitle) : "";
  return (
    <div>
      <Header />
      <MobileHeader/>
      <section
        id="hero"
        className="pt-72 pb-36"
        style={{ background: `#${backgroundColor}`, color: `#${primaryColor}` }}
      >
        <div className="flex flex-col md:flex-row px-8 md:px-16 items-left h-full">
          <div className="basis-8/12 md:pr-16 text-left md:text-left">
            <h3
              data-aos="fade"
              className="font-300 text-5xl max-w-[360px] leading-snug pb-32"
            >
              {summary.content.map((content, index) => {
                if (content.nodeType === "paragraph") {
                  const paragraphContent = content.content as ContentfulText[];
                  const paragraphText = paragraphContent
                    .map((node) => node.value)
                    .join(" ");
                  return (
                    <p key={index} style={{ color: `#${primaryColor}` }}>
                      {paragraphText}
                    </p>
                  );
                } else if (content.nodeType === "text") {
                  return (
                    <p key={index} style={{ color: `#${primaryColor}` }}>
                      {content.value}
                    </p>
                  );
                }
                return null;
              })}
            </h3>
          </div>
        </div>
        <div className="md:hidden text-[28px] flex flex-col gap-4 ps-8 mb-24 relative z-[50]">
            <Link className="w-fit" to="/chi-siamo"><h1 className={`font-normal underline`}>Chi siamo</h1></Link>
            <h1 className={`font-normal`}>Board</h1>
            <Link className="w-fit" to="/sponsorship"><h1 className={`font-normal underline`}>Sponsorship</h1></Link>
        </div>
        <div className="flex flex-col md:flex-row px-8 md:px-16 h-full">
          <div className="basis-3/12 md:pr-16 text-center md:text-left relative">
            <h1
              id="title"
              data-aos="fade"
              className={`text-left text-2xl uppercase font-semibold pb-4 md:leading-tight static md:absolute`}
            >
              BOARD
            </h1>
          </div>
          <div
            className="basis-9/12 text-center md:text-left text-lg"
            data-aos="fade"
          >
            <div className="flex w-full md:max-w-[80%] md:ml-[10%] text-left md:text-center">
              {body.content.map((content, index) => {
                if (content.nodeType === "paragraph") {
                  const paragraphContent = content.content as ContentfulText[];
                  const paragraphText = paragraphContent
                    .map((node) => node.value)
                    .join(" ");
                  return (
                    <p key={index} style={{ color: `#${primaryColor}` }}>
                      {paragraphText}
                    </p>
                  );
                } else if (content.nodeType === "text") {
                  return (
                    <p key={index} style={{ color: `#${primaryColor}` }}>
                      {content.value}
                    </p>
                  );
                }
                return null;
              })}
            </div>

            <div>
              <Consiglio cons={consiglio} inIcon={inIcon}></Consiglio>
              <Collegio collegio={collegio} linkedin={inIcon}></Collegio>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-left"
          className="pageFoot flex place-content-end pt-36 pb-12 px-24"
        >
          <Link
            to={"/" + suggestedSlug}
            className="flex text-white text-xl md:text-4xl hover:border-black"
            style={{ color: `#${primaryColor}` }}
            onClick={handleLinkClick}
          >
            Continua con{" "}
            <strong className="font-semibold">{suggestedPageTitle}</strong>
            <img src={arrow2} className="w-8 ml-6" />
          </Link>
        </div>
      </section>
      <Footer />
    </div>
  );
});

export default memo(Board);

import React, {useState, useEffect} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const CustomForm = ({ status, message, onValidated }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 &&
        onValidated(email);

    }

    useEffect(() => {
      if(status === "success") clearFields();
    }, [status])

    const clearFields = () => {
        setEmail('');
    }

    return (
        <>
        {( status === null || status === 'sending' || status === 'error') ?
            <form
                className="w-4/5 mb-10"
                onSubmit={(e) => handleSubmit(e)}
            >
                <input onChange={(e)=>setEmail(e.target.value)} label="Email" value={email} required className="w-[80%] md:w-full h-[60x] bg-white text-md py-6 md:p-4 border-[2px] border-gray-300 outline-none hover:border-gray-600 my-3" type="email"  placeholder="Inserisci la tua e-mail" />
                <input className="hidden" type="checkbox" label="Newsletter" defaultChecked/>
                <button className="bg-[#0c2957] text-xs font-bold w-full py-8 md:py-4 text-white tracking-widest">INVIA</button>
                <div className="hidden md:block mt-6 font-semibold md:font-normal w-full md:mt-2">Inserendo il tuo indirizzo e-mail, acconsenti alla nostra <a className='underline hover:text-[#27b4a5]' href='/privacy-policy'>privacy</a></div>
                {status === 'error' && <div className='text-sm text-red-600 font-normal mt-6'>{message}</div>}
            </form>
            :
            status === 'success' &&
                <div className='text-sm text-[#1b8073] font-normal mt-6'>Grazie, sei stato aggiunto alla newsletter</div>
        }
            <div className="block md:hidden mt-6 font-semibold md:font-normal md:mt-2">Inserendo il tuo indirizzo e-mail, acconsenti alla nostra <a className='underline hover:text-[#27b4a5]' href='/privacy-policy'>privacy</a></div>
        </>
    );
};


const MailchimpFormNews = () => {
    const [result, setResult] = useState(null);
    const url = `https://italyhlg.us22.list-manage.com/subscribe/post?u=245c1c35111c0766e4a980d04&id=5ba848767e`;
    const onValidated = async (email) => {
      setResult("sending")
      fetch(
        "https://aquamarine-halva-e2e241.netlify.app/api/subscribe-user",
        {
          method: "OPTIONS",
          headers: {
          "Content-Type": "application/json",
          },
              body: JSON.stringify({
              "email": email,
              "newsletter": "Newsletter",
          }),
          }
        )
        .then((res)=>{
          if(res.status===200) {
            setResult("success");
          } else {
            setResult("error");
          }
        })
        .catch((error)=>{
          setResult("error");
        })
    }
    return (
        <div className="mc__form-container">
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={result}
                        message={message}
                        // onValidated={formData => subscribe(formData)}
                        onValidated={onValidated}
                    />
                )}
            />
        </div>

    )
}

export default MailchimpFormNews;
import React from "react";
import "./App.css";
import Home from "./screens/Home";
import SinglePage from "./screens/SinglePage";
import JobOpportunities from "./screens/Jobs";
import SingleJob from "./screens/SingleJob";
import Board from "./screens/Board";
import Login from "./screens/Login";
import Recover from "./screens/Recover";
import Register from "./screens/Register";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateDocument from "./screens/PrivateDocument";
import NewsPage from "./screens/NewsPage";
import NewsList from "./screens/NewsList";
import Ipls from "./screens/Ipls";
import Newsletter from "./screens/NewsLetter";
import ReservedArea from "./screens/ReservedArea";
import ReservedAreaDoc from "./screens/ReservedAreaDocument"
import ReservedAreaPrivateDocs from "./screens/ReservedAreaPrivateDocs";
import AdminLogin from "./screens/AdminLogin";
import UserManager from "./screens/UserManager";
import AdminUserDetail from "./screens/AdminUserDetail";
import AssociatesList from "./screens/AssociatesList";
import Policy from "./screens/PrivacyPolicy";
import PrivateRoute from "./components/Private";
// import ReservedAreaHome from "./components/ReservedAreaHome";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:pageSlug" element={<SinglePage />} />
          {/* <Route path="/job-opportunities/:jobSlug" element={<SingleJob />} /> */}
          <Route path="/job-opportunities" element={<JobOpportunities/>} />
          <Route path="/job" element={<SingleJob />} />
          <Route path="/board" element={<Board />} />
          <Route path="/login" element={<Login />} />
          <Route path="/recover" element={<Recover />} />
          {/* <Route path="/signup/:status/:id" element={<Register/>}/> */}
          <Route path="/grildu249weriw239dsj771" element={<Register/>}/>
          <Route path="/iscrizione" element={<Register />} />
          <Route path="/registrazione" element={<Register />} />
          <Route path="/private-document" element={<PrivateDocument/>}/>
          <Route path="/newsletter" element={<Newsletter />} />
          {/* <Route path="/news/:nid" element={<NewsPage/>}/> */}
          <Route path="/news" element={<NewsPage/>}/>
          <Route path="/news-list" element={<NewsList/>}/>
          <Route path="/ipls" element={<Ipls/>}/>
          <Route path="/profilo" element={<PrivateRoute><ReservedArea/></PrivateRoute>}/>
          <Route path="/documenti" element={<PrivateRoute><ReservedAreaDoc/></PrivateRoute>}/>
          <Route path="/mostra-documento" element={<PrivateRoute><ReservedAreaPrivateDocs/></PrivateRoute>}/>
          <Route path="/elenco-associati" element={<PrivateRoute><AssociatesList/></PrivateRoute>}/>
          <Route path="/super-manager" element={<AdminLogin/>}/>
          {/* <Route path="/super-manager/home" element={<UserManager/>}/> */}
          <Route path="/user-manager" element={<UserManager/>}/>
          <Route path="/super-manager/edit/:uid" element={<AdminUserDetail/>}/>
          <Route path="/privacy-policy" element={<Policy policyType="privacy-policy"/>}/>
          <Route path="/cookie-policy" element={<Policy policyType="cookie-policy" />}/>
          {/* <Route path="/documenti-home" element={<ReservedAreaHome/>}/> */}
        </Routes>
        {/* <Footer /> */}
      </Router>
    </>
  );
}

export default App;

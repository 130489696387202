import React, { useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import config from '../config/index.json';
import { useNavigate } from 'react-router-dom';

function LatestNews() {

    const navigate = useNavigate();

    const CONTENTFUL_SPACE_ID = "qitjy5hyl5up"
    const CONTENTFUL_ACCESS_TOKEN = "TKo1zCQL_v1NQmGcRQXQVqayvDSqukrdCeRlbpEedyM"  
    const contentfulEndpointNews = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=news`;
  
    const { images } = config;
    const { arrow } = images;

    const [ totalNews, setTotalNews ] = useState<any>()
    const [ newsItems, setNewsItems ] = useState<any[]>()
    const [ isHovered, setIsHovered ] = useState(false)
    const [ hoveredIndex, setHoveredIndex ] = useState(-1)
    const [ slideIdx, setSlideIdx ] = useState(0);
    const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);

    useEffect(() => {
        AOS.init({ duration: 2000 });
        (async () => {
            try {
              const res = await fetch(contentfulEndpointNews);
              const data = await res.json();
              setTotalNews(data)
              setNewsItems(data.items.sort(function(item_a:any, item_b:any){return item_b.fields.date.localeCompare(item_a.fields.date)}));
            } catch (error) {
              console.error(error);
            }
          })();
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    const selectSlide = (index:any) => {
        setSlideIdx(index);
    }

    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleSelectNews = (index: any) => {
        const news_items = newsItems || [];
        const news_item = news_items[index];
        const nid = news_item.sys.id ?? ''
        const title =  news_item ? news_item.fields.title : ''
        navigate(`news?title=${title}&id=${nid}`)
      }
    const width = window.innerWidth;
    return (
        <section 
            id='news' 
            className='bg-darkblue relative mb-24' 
            data-aos={width<=767 ? "fade-down" : ""}
        >
            <h3 className='text-white text-3xl p-8 absolute z-10'> Ultime notizie</h3>
            <div className='md:grid md:grid-cols-3 md:flex-row items-center lastNews'>
                <div
                    onMouseEnter={()=>{setIsHovered(true); setHoveredIndex(0);}} 
                    onMouseLeave={()=>setIsHovered(false)}
                    onClick={()=>{handleSelectNews(0)}}
                    data-aos={width>767 ? "fade-down" : ""}
                    className={`place-items-end cursor-pointer bg-cover bg-black w-full item flex relative ${slideIdx==0 ? 'h-[600px]' : 'hidden'} md:block md:h-full` }>
                    <div 
                        style={{
                        zIndex: 1,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: `rgba(0, 0, 0, ${isHovered && hoveredIndex==0 ? '0.5': '0'})`, /* Adjust the alpha value to change the darkness */
                        pointerEvents: 'none', /* Allows mouse events to 'fall through' the overlay to the anchor tag */
                        }}
                    > 
                    </div>
                    <span className={`block h-auto absolute bottom-12 px-12 z-10 `}>
                        <div>
                            <span className='font-300 text-white'>{newsItems ? newsItems[0].fields.date : ''}</span><br />
                            <span className='text-white text-4xl leading-snug font-semibold pr-8'>{newsItems && newsItems[0]?.fields.title}</span>
                        </div>
                        <img src={arrow} className={`w-6 ${(isHovered && 0==hoveredIndex) ? 'mt-16 ms-4':'mt-10'} mb-4`} />
                    </span>
                    <img src={totalNews?.includes.Asset.filter((asset:any)=>{if(newsItems && newsItems?.length>0) {return asset.sys.id==newsItems[0].fields.coverImage.sys.id} else return 1})[0].fields.file.url} className="absolute mb-0 thumb-news-slide w-full h-full md:h-[700px] object-cover max-w-none h-full" />
                </div>
                <div
                    onMouseEnter={()=>{setIsHovered(true); setHoveredIndex(1);}} 
                    onMouseLeave={()=>setIsHovered(false)}
                    onClick={()=>{handleSelectNews(1)}}
                    data-aos={width>767 ? "fade-down" : ""}
                    data-aos-offset="250"
                    className={`place-items-end cursor-pointer bg-cover bg-black w-full item flex md:h-full md:block relative ${slideIdx==1 ? 'h-[600px]' : 'hidden'}`}>
                    <div 
                        style={{
                        zIndex: 1,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: `rgba(0, 0, 0, ${isHovered && hoveredIndex==1 ? '0.5': '0'})`, /* Adjust the alpha value to change the darkness */
                        pointerEvents: 'none', /* Allows mouse events to 'fall through' the overlay to the anchor tag */
                        }}
                    > 
                    </div>
                    <span className={`block h-auto absolute bottom-12 px-12 z-10`}>
                        <div>
                            <span className='font-300 text-white'>{newsItems ? newsItems[2].fields.date : ''}</span><br />
                            <span className='text-white text-4xl leading-snug font-semibold pr-8'>{newsItems ? newsItems[1].fields.title : ''}</span>
                        </div>
                        <img src={arrow} className={`w-6 ${(isHovered && 1==hoveredIndex) ? 'mt-16 ms-4':'mt-10'} mb-4`} />
                    </span>
                    <img src={totalNews?.includes.Asset.filter((asset:any)=>{if(newsItems && newsItems?.length>0) {return asset.sys.id==newsItems[1].fields.coverImage.sys.id} else return 1})[0].fields.file.url} className='thumb-news-slide mb-0 w-full h-full object-cover max-w-none absolute h-full' />
                </div>
                <div
                    onMouseEnter={()=>{setIsHovered(true); setHoveredIndex(2);}} 
                    onMouseLeave={()=>setIsHovered(false)}
                    onClick={()=>{handleSelectNews(2)}}
                    data-aos={width>767 ? "fade-down" : ""}
                    data-aos-offset="500"
                    className={`place-items-end cursor-pointer bg-cover bg-black w-full item flex md:h-full md:block relative ${slideIdx==2 ? 'h-[600px]' : 'hidden'}`}>
                    <div 
                        style={{
                        zIndex: 1,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: `rgba(0, 0, 0, ${isHovered && hoveredIndex==2 ? '0.5': '0'})`, /* Adjust the alpha value to change the darkness */
                        pointerEvents: 'none', /* Allows mouse events to 'fall through' the overlay to the anchor tag */
                        }}
                    > 
                    </div>    
                        <span className={`block h-auto absolute bottom-12 px-12 z-10`}>
                            <div>
                                <span className='font-300 text-white'>{newsItems ? newsItems[2].fields.date : ''}</span><br />
                                <span className='text-white text-4xl leading-snug font-semibold'>{newsItems ? newsItems[2].fields.title : ''}</span>
                            </div>
                            <img src={arrow} className={`w-6 ${(isHovered && 2==hoveredIndex) ? 'mt-16 ms-4':'mt-10'} mb-4`} />
                        </span>
                        <img src={totalNews?.includes.Asset.filter((asset:any)=>{if(newsItems && newsItems?.length>0) {return asset.sys.id==newsItems[2].fields.coverImage.sys.id} else return 1})[0].fields.file.url} className='thumb-news-slide mb-0 w-full h-full object-cover max-w-none absolute h-full' />
                </div>
            </div>
            <div data-aos="fade-down" className='flex w-full items-center justify-center py-8 gap-4 bg-[#e0f9fd] md:hidden'>
                <div onClick={()=>selectSlide(0)} className={`w-[8px] h-[8px] rounded-full ${slideIdx==0 ? 'bg-[#0c2957]' : 'bg-[#62c5ee]'}`}/>
                <div onClick={()=>selectSlide(1)} className={`w-[8px] h-[8px] rounded-full ${slideIdx==1 ? 'bg-[#0c2957]' : 'bg-[#62c5ee]'}`}/>
                <div onClick={()=>selectSlide(2)} className={`w-[8px] h-[8px] rounded-full ${slideIdx==2 ? 'bg-[#0c2957]' : 'bg-[#62c5ee]'}`}/>
            </div>
        </section>
    )
}

export default LatestNews
import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

AOS.init({
    offset: 50,
    duration: 50,
    easing: 'ease',
    delay: 0,
});

function Hero() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <section id='hero' className='bg-hero bg-no-repeat bg-center bg-cover relative w-full h-full md:h-auto '>
            <video className='hidden md:block' autoPlay loop muted > 
                <source className='hidden md:block' src={require('../videos/home.mp4')} type='video/mp4'/>
            </video>
            <video className='block md:hidden w-full' autoPlay loop muted> 
                <source src={require('../videos/home_mobile.mp4')} type='video/mp4'/>
            </video>
            <div className='hidden md:flex flex-col md:flex-row px-8  md:px-16 md:pt-16 md:pb-auto pt-auto pb-16 items-center h-full absolute bottom-0'>
                <div className='basis-10/12 md:pr-16 text-center md:text-left'>
                    <h1 data-aos="fade-down" className='text-white text-center md:mt-32 md:text-left text-xl md:text-2xl lg:text-6xl font-semibold pb-4 lg:leading-tight'>Supportiamo lo sviluppo di relazioni, alleanze strategiche e partnership per l'healthcare</h1>
                    <p data-aos="fade-down" className='text-white lg:text-[24px] md:text-[16px] text-[12px]'>Gruppo di relazione per il business development</p>
                </div>
            </div>
        </section>
    )
}

export default Hero
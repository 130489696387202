import React, {useState, useEffect} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const CustomForm = ({ status, message, onValidated }) => {
    const [email, setEmail] = useState();

    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 &&
        onValidated(email);

    }

    useEffect(() => {
      if(status === "success") clearFields();
    }, [status])

    const clearFields = () => {
        setEmail('');
    }

    return (
        <>
        { (status === null || status === 'sending' || status==='error')?
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="flex flex-wrap gap-4 pt-8">
                    <input onChange={(e)=>setEmail(e.target.value)} label="Email" value={email} required className="grow h-[60x] bg-white text-md py-3 border-[2px] p-4 border-[#1a1a1a]" type="email" placeholder="Inserisci la tua e-mail" />
                    <button className="w-full lg:w-auto bg-[#0c2957] text-[0.55rem] font-bold  px-[5rem] py-5 text-white tracking-widest">INVIA</button>
                </div>
                <div className="mt-2">Inserendo il tuo indirizzo e-mail, acconsenti alla nostra <a className='underline' href='/privacy-policy'>privacy</a></div>
                { status === 'error' &&
                    <div className='text-sm text-red-600 font-normal'>{message}</div>
                }
            </form>
            :status === 'success' &&
            <div className='text-sm text-[#0844a4] font-normal mt-6'>Grazie, sei stato aggiunto alla newsletter</div>
        }
        </>
    );
};


const MailchimpFormContainerNewsPage = () => {
    const [result, setResult] = useState(null);
    const url = `https://italyhlg.us22.list-manage.com/subscribe/post?u=245c1c35111c0766e4a980d04&id=5ba848767e`;
    const onValidated = async (email) => {
      setResult("sending")
      fetch(
        "https://aquamarine-halva-e2e241.netlify.app/api/subscribe-user",
        {
          method: "OPTIONS",
          headers: {
          "Content-Type": "application/json",
          },
              body: JSON.stringify({
              "email": email,
              "newsletter": "Newsletter",
          }),
          }
        )
        .then((res)=>{
          if(res.status===200) {
            setResult("success");
          } else {
            setResult("error");
          }
        })
        .catch((error)=>{
          setResult("error");
        })
    }
    return (
        <div className="mc__form-container">
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={result}
                        message={message}
                        // onValidated={formData => subscribe(formData)}
                        onValidated={onValidated}
                    />
                )}
            />
        </div>

    )
}

export default MailchimpFormContainerNewsPage;
import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Organization() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <section id='org' className=''>
            <div className='flex flex-col md:hidden'>
                <div data-aos="fade-down" className='bg-lightBlue flex'>
                    <span className='self-center'>
                        <p className='text-xl px-[30px] md:px-[15%] py-24'>Italy HLG promuove lo sviluppo professionale dei suoi membri attraverso l'organizzazione di corsi, appuntamenti, eventi e seminari.</p>
                    </span>
                </div>
                <div data-aos="fade-right" className="p-12 bg-cover bg-center bg-[url('images/box1.jpg')] h-[500px] md:h-auto"></div>
                <div data-aos="fade-right" className='self-center'>
                    <p className='text-xl px-[15%] py-24'><strong>Job opportunities</strong><br /><br />Italy HLG promuove lo scambio di esperienze professionali e favorisce nuove opportunità di networking. Organizza pitch & partner e agevola l'incontro tra aziende e manager.</p>
                </div>
                <div data-aos="fade-up" className="p-12 bg-cover bg-center bg-[url('images/box3.jpg')] h-[500px] md:h-auto"></div>
                <div data-aos="fade-left" className='self-center'>
                    <p className='text-xl px-[15%] py-24'><strong>IPLS Network</strong><br /><br />Italy HLG aderisce a <strong><u>IPLS - International Partnering in Lifescience Society </u></strong> per il business development & licensing nel settore Healthcare</p>
                </div>
                
            </div>
            <div className='grid grid-cols-1 md:grid-cols-3 h-auto pb-24  hidden md:grid'>
                <div data-aos="fade-right" className="p-12 bg-cover bg-center bg-[url('images/box1.jpg')]"></div>
                <div data-aos="fade-down" className='bg-lightBlue flex'>
                    <span className='self-center'>
                        <p className='text-xl px-[15%] py-24'>Italy HLG promuove lo sviluppo professionale dei suoi membri attraverso l'organizzazione di corsi, appuntamenti, eventi e seminari.</p>
                    </span>
                </div>
                <div data-aos="fade-left" className="hidden md:block p-12 bg-cover bg-center bg-[url('images/box2.jpg')]"></div>
                
                <div data-aos="fade-right" className='self-center'>
                    <p className='text-xl px-[15%] py-24'><strong>Job opportunities</strong><br /><br />Italy HLG promuove lo scambio di esperienze professionali e favorisce nuove opportunità di networking. Organizza pitch & partner e agevola l'incontro tra aziende e manager.</p>
                </div>
                <div data-aos="fade-up" className="p-12 bg-cover bg-center bg-[url('images/box3.jpg')]"></div>
                <div data-aos="fade-left" className='self-center'>
                    <p className='text-xl px-[15%] py-24'><strong>IPLS Network</strong><br /><br />Italy HLG aderisce a <strong><u>IPLS - International Partnering in Lifescience Society </u></strong> per il business development & licensing nel settore Healthcare</p>
                </div>
            </div>
        </section>
    )
}

export default Organization
import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import { SocialIcon } from 'react-social-icons'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../index.css";

import Footer from "../components/Footer";
import { useLocation, useParams } from "react-router";
import SimpleHeader from "../components/SimpleHeader";
import MailchimpFormContainerNewsPage from "../components/MailchimpFormContainerNewsPage";

type AuthorItem = {
  fields: {
    fullName: string;
    role: string;
    pirofilePicture: object;
  };
  metadata: any;
  sys: any;
}

type AuthorState = {
  item: AuthorItem;
  profilePicture: string;
};

type NewsState = {
  item: any;
  coverImage: string;
}

function NewsPage() {
  // const location = useLocation()
  // const { nid } = location.state;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nid = searchParams.get('id');
  
  const [ id, setId ] = useState(nid) 
  const [ author, setAuthor ] = useState<AuthorState>()
  const [ news, setNews ] = useState<NewsState>()
  
  const [ totalAuthor, setTotalAuthor ] = useState()
  const [ totalNews, setTotalNews ] = useState<any>([])

  const [ isHovered, setIsHovered ] = useState(false)
  const [ hoveredIndex, setHoveredIndex ] = useState(-1)

  const CONTENTFUL_SPACE_ID = "qitjy5hyl5up"
  const CONTENTFUL_ACCESS_TOKEN = "TKo1zCQL_v1NQmGcRQXQVqayvDSqukrdCeRlbpEedyM"

  const contentfulEndpointAuthor = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=author`;
  const contentfulEndpointNews = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=news&order=-fields.date`;

  const handleSelectNews = (news_id:string) => {
    setId(news_id);
  }
  
  useEffect(()=>{
    setId(nid)
    window.scrollTo(0,0)
  },[])

  useEffect(()=>{
    let news_item:any;
    (async () => {
      try {
        const res = await fetch(contentfulEndpointNews);
        const data = await res.json();
        setTotalNews(data);
        if (id) {
          try {
            news_item = data.items.filter((item:any) => item.sys.id == id)[0];
          } catch (error) {
            window.alert("News data does not exist.");
            return;
          }
        } else {
          news_item = data.items[0];
        }

        const news_img = data.includes.Asset.filter((asset:any)=>{
          return asset.sys.id == news_item.fields.coverImage.sys.id;
        })[0].fields.file.url
        setNews({'item':news_item, 'coverImage':news_img});
    
        // Fetch author data
        const authorRes = await fetch(contentfulEndpointAuthor);
        const authorData = await authorRes.json();
        setTotalAuthor(authorData)

        const authorItem = authorData.items.find((item:any) => item.sys.id === news_item.fields.author.sys.id);
        const authorImage = authorData.includes.Asset.find((item:any) => item.sys.id === authorItem.fields.pirofilePicture.sys.id).fields.file.url
        
        setAuthor({'item':authorItem, 'profilePicture': authorImage})
      } catch (error) {
        console.error(error);
      }
    })();

  },[id])

  let formattedDate = ""
  if (news) {
    const partsDate = news.item.fields.date.split("-");
    formattedDate = `${partsDate[2]}/${partsDate[1]}/${partsDate[0]}`
  }
  return (
    <>
      <section
        id="hero"
        style={{backgroundImage:`url(${news?.coverImage})`}}
        className="brightness-100 bg-no-repeat w-full bg-center bg-cover pt-80 pb-6 h-[40vh]"
      >

      </section>
      <SimpleHeader/>
      <section>
        <div className="pb-[50px]">
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className="col-span-2 pt-[100px] ">
                <div className="grid grid-cols-1 md:grid-cols-5">
                    <div className="col-span-2"></div>
                    <div className="col-span-3 w-full divide-y divide-[#27b4a5] px-24 md:px-0">
                        <div className="flex justify-between flex-nowrap mb-4 -4 ">
                            <div className="">
                                <h1 className="text-sm font-bold mb-2">{author?.item?.fields?.fullName}</h1>
                                <p  className="text-sm">{author?.item.fields.role}</p>
                            </div>
                            <div>
                                <img src={author?.profilePicture} width={55} height={55} className="border rounded-full" alt="" />
                            </div>
                        </div>
                        <div className="flex justify-between pt-4">
                            <h1 className="text-sm text-gray-500 font-[600]">Share article:</h1>
                            <div>
                                <SocialIcon style={{width:'28px', height:'28px', marginRight:'5px'}} url="www.mailto.com"/>
                                <SocialIcon href="https://www.facebook.com" style={{width:'28px', height:'28px', marginRight:'5px'}} url="www.facebook.com"/>
                                <SocialIcon href="https://linkedin.com" style={{width:'28px', height:'28px', marginRight:'5px'}} url="www.linkedin.com"/>
                                <SocialIcon href="https://twitter.com" style={{width:'28px', height:'28px'}} url="www.twitter.com"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-3 z-10 md:mt-[-120px]">
              <div className=" z-50 bg-white pt-24 pl-24 pr-40">
                <h3 className="text-[#27b4a5] font-semibold pb-6">{formattedDate}</h3>
                <h1 className="text-[3.3rem] text-[#0c2957] leading-[4.5rem] font-bold mb-10">{news?.item.fields.title}</h1>
                {
                  news?.item.fields.newsBody.content.map((desc:any, index:number)=>{
                    return (
                      <p key={index} className="mt-6 text-md font-[600]">
                      {
                        desc?.content.map((p:any, indexp:number)=>{
                          return (
                            <>                            
                              {p?.content && 
                                <>
                                  {p?.nodeType == "hyperlink" ?
                                   <a className="text-[#00a695] hover:underline hover:cursor-pointer" href={p?.data.uri}>{p.content?.value || p?.content[0]?.value}</a> 
                                   :
                                   <p>{p.content?.value}</p> 
                                  }
                                </>
                              }
                              <span className={(p.marks && p.marks[0]?.type=='bold')?'font-bold':'font-normal'}>{p.value}</span>
                            </>
                          )
                        })
                      }
                      </p>
                    )
                  })
                }

              {news?.item.fields.cta && <a className="uppercase block py-6 mt-10 bg-[#00a695] text-center text-white text-xs font-semibold w-[280px]" href={news?.item.fields.ctaLink}>{news?.item.fields.cta}</a> }
              </div>
              <div className="bg-[#e0fdf9] ml-24 mr-10 px-12 py-8 mt-16">
                <h1 className="text-2xl text-[#0c2957] font-bold w-[180px] mb-4">Iscriviti alla newsletter</h1>
                <p className="max-w-[400px] font-semibold">
                    Iscriviti per ricevere periodicamente notizie e aggiornamenti sul mondo di Italy
                    HLG, incluse le nostre iniziative in anteprima, i nostri eventi e quello che 
                    nella nostra industria ci ispira e ci guida. Iscrivendoti accetti <a className="underline" href="">questi termini.</a>
                </p>
                <MailchimpFormContainerNewsPage/>
                {/* <form action="">
                  <div className="flex flex-wrap gap-4 pt-8">
                    <input className="w-[250px] h-[60x] bg-white text-md py-3 border-[2px] p-4 border-[#1a1a1a]" type="email" placeholder="Inserisci la tua e-mail" />
                    <button className="bg-[#0c2957] text-[0.55rem] font-bold  px-[7rem] py-5 text-white tracking-widest">INVIA</button>
                  </div>
                  <div className="mt-2">Inserendo il tuo indirizzo e-mail, acconsenti alla nostra privacy</div>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      
        <Slider  slidesToShow={3} slidesToScroll={1}>
        {
          totalNews?.items?.map((news_item:any, index:number)=>{
            const partsDate = news_item.fields.date.split('-');
            const formattedDate = `${partsDate[2]}/${partsDate[1]}/${partsDate[0]}`
            return (
              <div>
                <a href="#header" onClick={()=>handleSelectNews(news_item.sys.id)} onMouseEnter={()=>{setIsHovered(true); setHoveredIndex(index);}} onMouseLeave={()=>setIsHovered(false)} style={{backgroundImage:`url(${totalNews.includes.Asset.filter((asset:any)=>{return asset.sys.id==news_item.fields.coverImage.sys.id})[0].fields.file.url})`}} className={` bg-hero bg-center bg-cover relative  h-screen flex flex-col ${index==0?'justify-between':'justify-end'} px-12 py-8`}>
                  <div 
                    style={{
                      zIndex: 1,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: `rgba(0, 0, 0, ${isHovered && hoveredIndex==index ? '0.5': '0'})`, /* Adjust the alpha value to change the darkness */
                      pointerEvents: 'none', /* Allows mouse events to 'fall through' the overlay to the anchor tag */
                    }}
                  ></div>
                  {
                    index == 0 &&
                    <h1 className="text-gray-100 text-2xl z-10 mt-16">More news</h1>
                  }
                  <div className="mb-0 text-white z-10">
                    <h6 className={`text-xs ${(isHovered && index==hoveredIndex) ? 'mb-8':'mb-3'}}`}>{formattedDate}</h6>
                    <h1 className={`text-2xl ${(isHovered && index==hoveredIndex) ? 'mb-8':'mb-3'}`}>{news_item.fields.title}</h1>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${(isHovered && index==hoveredIndex) ? 'ml-6':'ml-0'}`}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                    </svg>
                  </div>
                </a>
              </div>
            )
          })
        }         
        </Slider>

      </section>
      <Footer></Footer>
    </>
  );
}

export default NewsPage;

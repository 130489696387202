import { color } from 'framer-motion';
import React, { useState } from 'react'

interface PropsType  {
    category: String;
    color: String;
    numbers: any;
    selected: any;
    onSelect: ()=>void;   
}

function DocumentCategory(props:PropsType) {
  const [hovered, setHovered] = useState(false)
  const border_width = (props.selected ? 'border-4' : 'border-0');
  return (
    <div onClick={props.onSelect} onMouseEnter={()=>setHovered(true)} onMouseLeave={()=>setHovered(false)} style={{backgroundColor:hovered ?'#0c2957':`#${props.color}`}} className={`document-category-bg w-[135px] h-[135px] rounded-lg ${border_width}  border-[#0c2957] relative`}>
        <div className='absolute left-6 bottom-6 text-sm text-white font-[700]'>
            {props.category}{' ('}{props.numbers}{')'}
        </div>
    </div>
  )
}

export default DocumentCategory
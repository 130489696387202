import React from 'react'
import LazyShow from './LazyShow'
import SimpleHeader from './SimpleHeader'
import MobileHeader from './MobileHeader'
import heroBackground from '../images/bg_1.png';

interface ResponsiveHeaderProps {
    notFull?: boolean,
    backgroundImage?: string,
    title?: string,
}

const ResponsiveHeader: React.FC<ResponsiveHeaderProps> = (props) => {
  const { title, backgroundImage, notFull } = props;
  return (
    <div>
        <section
            id="hero"
            style={{backgroundImage:`url(${backgroundImage??heroBackground})`}}
            className={`hidden md:block bg-no-repeat ${notFull ? 'w-3/5' : 'w-full'} w-full bg-center bg-cover pt-80 pb-6 h-[40vh] brightness-100`}
        >
        </section>
        <LazyShow>
            <section id='hero' className='w-full md:hidden bg-hero bg-no-repeat bg-center bg-cover relative h-auto lg:mt-[-300px] md:mt-[-150px]'>
                <video autoPlay loop muted className='w-full'> 
                    <source src={require('../videos/news.mp4')} type='video/mp4'/>
                </video>
                <div className="text-4xl text-white absolute left-[60px] bottom-[40%] z-[101] opacity-80">
                    {title}
                </div>
            </section>
        </LazyShow>
        <SimpleHeader/>
        <MobileHeader/>
    </div>
  )
}

export default ResponsiveHeader
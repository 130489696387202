import React, { useContext, useEffect, useState } from 'react';
import config from '../config/index.json';
import { AuthContext } from '../context/UserContext';
import { getDatabase, ref, get } from 'firebase/database';

const SimpleHeader = () => {
    const { images } = config;
    const { logo } = images;
    const { user, loading } = useContext(AuthContext)
    const [ loginState, setLoginState ] = useState(false);

    useEffect(() => {
        const navItems = document.querySelectorAll('.navItem');
        navItems.forEach((navItem) => {
            navItem.addEventListener('mouseover', () => {
                const bgHeader = document.querySelector('#hero');
                if (bgHeader) {
                  bgHeader.classList.replace('brightness-100', 'brightness-50')
                }
            });
            navItem.addEventListener('mouseout', () => {
                const bgHeader = document.querySelector('#hero');
                if (bgHeader) {
                  bgHeader.classList.replace('brightness-50', 'brightness-100')
                }
            });
        });

        if (!loading) {
            if (user) {
                const db = getDatabase();
                get(ref(db, "users/" + user?.uid)).then((userInfo)=>{
                    const verified = userInfo.val()?.verified;
                    if (verified==undefined) {
                        setLoginState(false)
                    } else {
                        setLoginState(true)
                    }
                })
                .catch((error)=>{
                    setLoginState(false)
                });
            } else {
                setLoginState(false)
            }
        }

        return () => {
            navItems.forEach((navItem) => {
                navItem.removeEventListener('mouseover', () => {});
                navItem.removeEventListener('mouseout', () => {});
            });
        };
    }, []);

    return (
        <div id="header" className='hidden md:block bg-header absolute w-full top-0 z-20 pt-4 md:pt-16 pb-4 px-4 md:px-16'>
            <div className='headWrap flex justify-center md:justify-between flex-col-reverse	md:flex-row'>
                <div className='flex items-center'>
                    <a href="/">
                        <img src={logo} className="mt-16 md:my-4 w-40 block mx-auto" alt="logo" />
                    </a>
                </div>
                <div className='flex items-center'>
                    <ul className='mainNav'>
                        <li className='navItem' data-item='chisiamo'>
                            <a href='/chi-siamo'>Chi siamo</a>
                        </li>
                        <li className='navItem' data-item='news'>
                            <a href='/news-list'>News ed eventi</a>
                        </li>
                        <li className='navItem' data-item='network'>
                            <a href='/network-internazionale'>Network</a>
                        </li>
                        <li className='navItem' data-item='edu'>
                            <a href='/'>Educazione</a>
                        </li>
                    </ul>
                </div>
                <div className='flex items-center'>
                {
                    (!loginState) ?
                        <a
                            key="login"
                            href="/login"
                            className="hidden md:inline text-[#00C3AF] text-base font-bold ml-4 cursor-pointer transition hover:border-b"
                            >
                            Accedi / Iscriviti
                        </a> : 
                        // <div
                        //     onClick={()=>{logOut()}}
                        //     key="logout"
                        //     className="hidden md:inline text-white text-base font-medium ml-4 cursor-pointer transition hover:border-b"
                        //     >
                        //     Logout
                        // </div>
                        <a
                            href='/profilo'
                            key="profile"
                            className="hidden md:inline text-white text-base font-medium ml-4 cursor-pointer transition hover:border-b"
                            >
                            Area riservata
                        </a>
                }
                </div>
            </div>
            {/* <div className='overlay'></div> */}
        </div>
    )
}

export default SimpleHeader
import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Asset,
  ContentfulParagraph,
  ContentfulDocument,
  ContentfulText,
  ContentfulLink,
  ContentfulList,
} from "../types";

import config from "../config/index.json";

interface ContentfulResponse {
  items: Job[];
  includes: {
    Asset: Asset[];
  };
}

interface LogoDetails {
  sys: {
    type: string;
    linkType: string;
    id: string;
  };
  fields: {
    file: {
      url: string;
    };
  };
}

interface DownloadDetails {
  sys: {
    type: string;
    linkType: string;
    id: string;
  };
  fields: {
    file: {
      url: string;
    };
  };
}

interface Job {
  sys: {
    id: string;
    type: string;
  };
  fields: {
    title: string;
    slug: string;
    logo: {
      sys: {
        id: string;
      };
    };
    dateLastUpdated: string;
    position: string;
    location: string;
    idReference: string;
    summary: ContentfulDocument;
    description: ContentfulDocument;
    downloadable: {
      sys: {
        id: string;
      };
    };
    contact: string;
  };
}

AOS.init({
  offset: 50,
  duration: 120,
  easing: "ease",
  delay: 0,
});

const CONTENTFUL_SPACE_ID = "qitjy5hyl5up";
const CONTENTFUL_ACCESS_TOKEN = "0PL0qCiNnT4GhSRGoDG8E69TrtEvJwipPHfutAMhECU";
const contentfulEndpoint = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=jobPosition`;

export const slugify = (str: string): string => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

const handleLinkClick = () => {
  window.scrollTo(0, 0);
};

const SingleJob = () => {
  const { images } = config;
  const { arrow2black } = images;
  // const { jobSlug } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobSlug = searchParams.get('id');

  const [selectedPage, setSelectedPage] = useState<Job | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [logoDetails, setLogoDetails] = useState<LogoDetails | undefined>(
    undefined
  );
  const [LogoURL, setLogoURL] = useState<string | undefined>(undefined);
  const [JobTitle, setJobTitle] = useState<string | undefined>(undefined);
  const [JobDate, setJobDate] = useState<string | undefined>(undefined);
  const [JobPos, setJobPos] = useState<string | undefined>(undefined);
  const [JobLocation, setJobLocation] = useState<string | undefined>(undefined);
  const [JobReference, setJobReference] = useState<string | undefined>(
    undefined
  );
  const [JobSummary, setJobSummary] = useState<ContentfulDocument | undefined>(
    undefined
  );
  const [JobDescription, setJobDescription] = useState<
    ContentfulDocument | undefined
  >(undefined);
  const [downloadDetails, setDownloadDetails] = useState<
    DownloadDetails | undefined
  >(undefined);
  const [DownloadURL, setDownloadURL] = useState<string | undefined>(undefined);
  const [JobContact, setJobContact] = useState<string | undefined>(undefined);
  const [suggestedPageTitle, setSuggestedPageTitle] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(contentfulEndpoint);
        const data = await res.json();

        const job = (data as unknown as ContentfulResponse).items.find(
          (job: Job) => slugify(job.fields.title) === jobSlug
        );
        setSelectedPage(job);
        setJobTitle(job?.fields.title);
        setJobDate(job?.fields.dateLastUpdated);
        setJobPos(job?.fields.position);
        setJobLocation(job?.fields.location);
        setJobReference(job?.fields.idReference);
        setJobSummary(job?.fields.summary);
        setJobDescription(job?.fields.description);
        setJobContact(job?.fields.contact);

        // Fetch logo details
        const logoId = job?.fields.logo.sys.id;
        const logoDetailsResponse = await fetch(
          `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/assets/${logoId}?access_token=${CONTENTFUL_ACCESS_TOKEN}`
        );
        const logoDetailsData: LogoDetails = await logoDetailsResponse.json();
        setLogoDetails(logoDetailsData);
        setLogoURL(logoDetailsData.fields.file.url);

        // Fetch downloadable details
        const downloadId = job?.fields.downloadable.sys.id;
        const downloadDetailsResponse = await fetch(
          `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/assets/${downloadId}?access_token=${CONTENTFUL_ACCESS_TOKEN}`
        );
        const downloadDetailsData: DownloadDetails =
          await downloadDetailsResponse.json();
        setDownloadDetails(downloadDetailsData);
        setDownloadURL(downloadDetailsData.fields.file.url);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch pages. Please try again later.");
      }
    })();
  }, [jobSlug]);

  if (!setSelectedPage) {
    return <div>Loading...</div>;
  }

  const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  const formatDate = (JobDate: string) => {
    const dateObject = new Date(JobDate);
    const day = dateObject.getDate();
    const month = months[dateObject.getMonth()];
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`;
  };
  const formattedDate = JobDate ? formatDate(JobDate) : "";

  return (
    <div>
      {/* <Header /> */}
      <section id="hero" className="pt-72 pb-36 bg-[#c3f0c8]">
        <div className="flex flex-col md:flex-row px-8 md:px-16 items-center h-full">
          <div className="basis-8/12 md:pr-16 text-center md:text-left relative">
            <h1
              id="title"
              className="text-center md:text-left text-6xl pb-24 md:leading-tight max-w-[66%]"
              data-aos="fade"
            >
              {JobTitle}
            </h1>
          </div>
        </div>

        <div className="flex flex-col md:flex-row px-8 md:px-16 h-full">
          <div className="basis-4/12 md:pr-16 text-center md:text-left relative">
            <h1
              id="title"
              data-aos="fade"
              className="text-center md:text-left text-2xl uppercase font-semibold pb-8 md:leading-tight"
            >
              JOB OPPORTUNITIES
            </h1>
            <div className="max-w-[240px] shadow-md mb-8">
              <img src={LogoURL} />
            </div>
          </div>
          <div className="basis-8/12 md:pr-16 text-center md:text-left relative text-lg jobDesc">
            <div className="text-[#00a69a] font-semibold" data-aos="fade">
              {formattedDate}
            </div>
            <br />
            <div className="text-black pb-4" data-aos="fade">
              <strong className="font-semibold">Posizione: </strong>
              {JobPos}
            </div>
            <div className="text-black pb-4" data-aos="fade">
              <strong className="font-semibold">Location: </strong>
              {JobLocation}
            </div>
            <div className="text-black pb-12" data-aos="fade">
              <strong className="font-semibold">Id-reference: </strong>
              {JobReference}
            </div>
            <div className="text-black pb-8" data-aos="fade">
              {JobSummary?.content.map((content, index) => {
                if (content.nodeType === "paragraph") {
                  const paragraphContent = (content as ContentfulParagraph)
                    .content;
                  const paragraphText = paragraphContent.map(
                    (node, nodeIndex) => {
                      if (node.nodeType === "text") {
                        return (
                          <span key={nodeIndex}>
                            {(node as ContentfulText).value}
                          </span>
                        );
                      }
                      return null; // Handle other node types if needed
                    }
                  );

                  return (
                    <p key={index} className="pb-6">
                      {paragraphText}
                    </p>
                  );
                }
                return null; // Handle other content types if needed
              })}
            </div>
            <div className="text-[#00a69a] font-semibold" data-aos="fade">
              Job description
            </div>
            <br />
            <div className="text-black pb-8" data-aos="fade">
              {JobDescription?.content.map((content, index, array) => {

                let renderedContent = null;

                if (content.nodeType === "paragraph") {
                  const paragraphContent = (content as ContentfulParagraph)
                    .content;
                  renderedContent = paragraphContent.map((node, nodeIndex) => {
                    if (node.nodeType === "text") {
                      if (node.marks.some((mark) => mark.type === "bold")) {
                        return (
                          <span key={nodeIndex}>
                            <strong>{(node as ContentfulText).value}</strong>
                            <br />
                            <br />
                          </span>
                        );
                      } else {
                        return (
                          <span key={nodeIndex}>
                            {(node as ContentfulText).value}
                            <br />
                            <br />
                          </span>
                        );
                      }
                    } else if (node.nodeType === "hyperlink") {
                      const linkContent = (node as ContentfulLink).content;
                      let linkText = "";

                      if (Array.isArray(linkContent)) {
                        linkText = linkContent
                          .map((textNode) => {
                            if (textNode.nodeType === "text") {
                              return (textNode as ContentfulText).value;
                            }
                            return "";
                          })
                          .join("");
                      }

                      const linkUri = (node as ContentfulLink).data.uri;
                      return (
                        <a key={nodeIndex} href={linkUri} target="_blank">
                          {linkText}
                        </a>
                      );
                    } else {
                      return null;
                    }
                  });
                } else if (content.nodeType === "blockquote") {
                  return (
                    <blockquote key={index} data-aos="fade">
                      {(content as ContentfulText).value}
                    </blockquote>
                  );
                } else if (content.nodeType === "heading-1") {
                  return (
                    <h3 key={index} data-aos="fade">
                      {(content as ContentfulText).value}
                    </h3>
                  );
                } else if (content.nodeType === "unordered-list") {
                  const ulContent = (content as ContentfulList).content;
                  return (
                    <ul data-aos="fade" key={index}>
                      {ulContent.map((listItem, listItemIndex) => {
                        if (
                          "content" in listItem &&
                          Array.isArray(listItem.content) &&
                          listItem.content.length > 0 &&
                          "content" in listItem.content[0] &&
                          Array.isArray(listItem.content[0].content) &&
                          listItem.content[0].content.length > 0 &&
                          "value" in listItem.content[0].content[0]
                        ) {
                          const value = (
                            listItem.content[0].content[0] as ContentfulText
                          ).value;
                          return <li key={listItemIndex}>{value}</li>;
                        } else {
                          // Handle cases where the structure doesn't match the expected pattern
                          return null;
                        }
                      })}
                    </ul>
                  );
                }

                return <p data-aos="fade">{renderedContent}</p>;
              })}
            </div>
            <div className="mb-14" data-aos="fade">
              <a
                href={DownloadURL}
                target="_blank"
                className="btn bg-[#00a695] text-white hover:bg-[#00bdaa] transition text-base font-semibold px-12 py-6 block"
              >
                SCARICA LA JOB DESCRIPTION
              </a>
            </div>
            <div className="text-black pb-6" data-aos="fade">
              <strong className="font-semibold">
                Per informazioni e candidature:{" "}
              </strong>
              <u>
                <a href={`mailto:${JobContact}`}>{JobContact}</a>
              </u>
            </div>
            <div className="text-neutral-600 pb-12" data-aos="fade">
              Ultimo aggiornamento: {formattedDate}
            </div>
          </div>
        </div>

        <div
          data-aos="fade-left"
          className="pageFoot flex place-content-end pt-36 pb-12 px-24 dark"
        >
          <Link
            to="/job-opportunities"
            className="flex text-xl md:text-4xl hover:border-black"
            onClick={handleLinkClick}
          >
            Continua con{" "}
            <strong className="font-semibold">Job Opportunities</strong>
            <img src={arrow2black} className="w-8 ml-6" />
          </Link>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default SingleJob;

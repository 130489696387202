import { useContext, useEffect } from "react"
import { AuthContext } from "../context/UserContext"
import { useNavigate } from "react-router";
import { ref, get, getDatabase } from "firebase/database";

const PrivateRoute = (props) => {
    const { children } = props;
    const navigate = useNavigate();
    const { user, loading } = useContext(AuthContext);
    useEffect(()=>{
        if (!loading) {
            if (user) {
                const db = getDatabase();
                get(ref(db, "users/" + user?.uid)).then((userInfo)=>{
                    const verified = userInfo.val()?.verified;
                    if (verified==undefined) {
                        navigate('/login')
                    }
                })
                .catch((error)=>{
                    navigate('/login')
                });
            } else {
                navigate('/login')
                // navigate('/')
            }
        }

    },[user])
    return (
        <>
            {children}
        </>
    )
}

export default PrivateRoute

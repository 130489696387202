import React, { useContext, useState } from "react";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/UserContext";
import SimpleHeader from "../components/SimpleHeader";

function Recover() {
  const { resetPassword } = useContext(AuthContext);
  const [submitted, setSubmitted] = useState(false); 

  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.preventDefault();
    const form = e.target;
    const email = form.email.value;
    console.log("email", email);
    resetPassword(form["email"].value)
      .then(() => {
        setSubmitted(true)
      })
      .catch((err: any) => {
        console.error(err.code);
        console.error(err.message);
      });
  };

  return (
    <>
      <section
        id="hero"
        className="brightness-100 bg-hero bg-no-repeat w-full  bg-center bg-cover pt-80 pb-6 h-[40vh]"
      >
        {/* <video autoPlay loop muted className='top-0 left-0 w-full absolute'> 
          <source src={require('../videos/home.mp4')} type='video/mp4'/>
        </video> */}
      </section>
      <SimpleHeader/>
      <section>
        <div className="h-[800px]">
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className="col-span-2 pt-[100px] pl-[60px] md:pr-[20px] lg:pr-[60px]">
              <hr className="color-[#00a695] border border-[#00a695] w-4/5" />
              <div className="mt-[20px]">
                <strong>Hai bisogno di aiuto?</strong>
              </div>
              <a className="text-[#00a695]" href="">
                info@italyhlg.it
              </a>
            </div>
            <div className="col-span-3 z-10 mt-[-120px]">
              <div className="z-50 bg-white p-24">
                <div className="text-5xl text-[#0c2957] font-[700]">
                  <h1 className="mb-6">Accesso</h1>
                  <h1>Area Riservata</h1>
                </div>
                <p className="mt-6 font-[600]">
                  Recupera password - Inserisci la mail con cui hai effettuato
                  la registrazione e riceverai un link per fare un reset della
                  tua password. Grazie
                </p>
                {
                submitted?
                <div className="py-6">
                  <hr className="w-3/4"/>
                  <h1 className="text-[#17ad9f] pt-4 pb-6 font-semibold">
                    Abbiamo inviato una mail al tuo indirizzo con le istruzioni per il recupero della tua password
                  </h1>
                  <Link to="/login" className="underline text-xl text-[#253f98]">Vai al login</Link>
                </div>
                :
                <form onSubmit={handleSubmit} className="mt-6 w-[80%]">
                  <input
                    id="email"
                    name="email"
                    className="block w-full p-5 my-4 border border-1"
                    type="email"
                    placeholder="E-mail"
                    required
                  />
                  <button
                    className="block w-full p-6 my-4 uppercase text-white text-xs bg-[#00a695]"
                    type="submit"
                  >
                    RECUPERA PASSWORD
                  </button>
                </form>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Recover;

import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import config from "../config/index.json";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/UserContext";
import hearBackgroundImage from "../images/hero.jpg"
// import SimpleHeader from "../components/SimpleHeader";

import { getDatabase, ref, get } from "firebase/database";
import ResponsiveHeader from "../components/ResponsiveHeader";
import MobileMenu from "../components/MobileMenu";

function Login() {
  const { signIn, loading, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { images } = config;
  const [ errorMessage, setErrorMessage ] = useState("");
  const { logo } = images;

  useEffect(()=>{
    if (user?.verified) {
      navigate('/documenti');
    }
  },[user])

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.target;
    const email = form.email.value;
    const password = form.password.value;
    signIn(email, password)
      .then((result: any) => {
        form.reset();
        const user = result.user
        const db = getDatabase();
        get(ref(db, "users/" + user.uid)).then((userInfo)=>{
          if (userInfo.val()?.verified) {
            navigate('/documenti');
          } else {
            setErrorMessage("notverified");
          }
        })
        .catch((error:any)=>{
          console.error('error',error);
        })
      })
      .catch((error: any) => {
        setErrorMessage("wrongpassword");
        console.error(error);
        form.reset();
      });
  };
  return (
    <>
      {/* <section
        id="hero"
        className="bg-hero brightness-100 bg-no-repeat w-full bg-center bg-cover pt-80 pb-6 h-[40vh]"
      >
      </section>
      <SimpleHeader/> */}
      <ResponsiveHeader backgroundImage={hearBackgroundImage} title="Area riservata" />
      <section>
        <div className="pb-32 md:pb-[200px]">
          <div className="flex flex-col-reverse md:grid md:grid-cols-5">
            <div className="col-span-2 pt-0 md:pt-[100px] ps-8 pr-16 md:pl-[60px] md:pr-[20px] lg:pr-[60px]">
              <hr className="hidden md:block color-[#00a695] border border-[#00a695] w-full md:w-4/5" />
              <div className="mt-[20px] text-xl font-semibold">
                Hai bisogno di aiuto?
              </div>
              <a className="text-[#00a695] text-xl" href="">
                info@italyhlg.it
              </a>
              <div className="mt-8 text-xl text-[#0c2957]">
                Iscriviti adesso a <Link className="underline" to="/iscrizione">Italy HLG</Link>
              </div>
              {/* <p className="mt-[40px]">
                L 'iscrizione a Italy HLG permette di usufruire di:
              </p>
              <ul className="mt-[20px] pl-[40px] list-disc gap-[10px]">
                <li>
                  Ampie possibilità di Network con
                  <strong>
                    altri professionisti del licensing e del business
                    development
                  </strong>
                  di aziende e organizzazioni nel settore Healthcare.
                </li>
                <li>
                  <strong>Accesso alla sezione riservata</strong>del sito.
                </li>
                <li>
                  Offre{" "}
                  <strong>partecipazione ad eventi di alta formazione</strong>
                  (BD Academy), godendo di condizioni economiche di favore
                  riservate agli associati.
                </li>
                <li>
                  Partecipazione ad altre
                  <strong>iniziative culturali e ricreative</strong>organizzate
                  dall'associazione con la finalità di
                  <strong>sviluppare il network.</strong>
                </li>
                <li>
                  Partecipazione ad{" "}
                  <strong>
                    eventi organizzati da IPLS International Partnering in
                    Lifescience Society e dalle altre associate EU.
                  </strong>
                </li>
                <li>
                  <strong>
                    Abbonamento al Business Development & Licensing Journal.
                  </strong>
                </li>
              </ul> */}
              {/* Button */}
              
             
              {/* <div className="w-full mt-6 flex justify-center items-center">
                <a
                  className="w-[80%] px-20 py-6 bg-[#12357a] text-white text-center text-xs"
                  href="/iscrizione"
                >
                  ISCRIZIONE
                </a>
              </div> */}
            </div>
            <div className="col-span-3 z-10 mt-[-120px]">
              <div className=" z-50 bg-white p-16 md:p-24">
                <div className="hidden md:block text-5xl text-[#0c2957] font-[700]">
                  <h1 className="mb-6">Accesso</h1>
                  <h1>Area Riservata</h1>
                </div>
                <p className="mt-6 font-[600]">
                In area riservata sono disponibili per gli iscritti Italy HLG le presentazioni dei relatori ospiti dei nostri Meeting, link a webinar e immagini e quant'altro sia stato raccolto attraverso le nostre attività, oltre alla collezione delle varie uscite del BD and Licensing Journal.

                </p>
                <form className="mt-12 md:mt-6 w-full md:w-[80%]" onSubmit={handleSubmit}>
                  <input
                    id="email"
                    name="email"
                    className="block w-full p-5 my-4 border border-1"
                    type="email"
                    placeholder="E-mail"
                    required
                  />
                  <input
                    id="password"
                    name="password"
                    className="block w-full p-5 my-4 border border-1"
                    type="password"
                    placeholder="Password"
                    required
                  />
                  <button
                    className="block w-full p-6 my-4 uppercase text-white text-xs bg-[#00a695]"
                    type="submit"
                  >
                    LOGIN
                  </button>
                  <div className="w-full text-left px-6 flex flex-col">
                    {/* <div>
                      Se non hai un account, registrati {" "}<Link to="/iscrizione" className="underline">qui.</Link>
                    </div> */}
                    {errorMessage && 
                      <>
                        <div className="text-red-600">Le informazioni inserite non sono corrette! <span className="text-[#2e909b]">Riprova</span></div>
                      </>
                    }
                    <Link to="/recover" className="mt-2 text-sm md:text-lg underline">
                      Hai dimenticato la password?  Recuperala
                    </Link>
                  </div>
                </form>
                <div className="mt-20">
                  <p className="mt-[40px]">
                    L 'iscrizione a Italy HLG permette di usufruire di:
                  </p>
                  <ul className="mt-[20px] pl-[40px] list-disc gap-[10px]">
                    <li>
                      Ampie possibilità di Network con
                      <strong> altri professionisti del licensing e del business
                        development </strong>
                      di aziende e organizzazioni nel settore Healthcare.
                    </li>
                    <li>
                      <strong>Accesso alla sezione riservata </strong>del sito.
                    </li>
                    <li>
                      Offre{" "}
                      <strong>partecipazione ad eventi di alta formazione </strong>
                      (BD Academy), godendo di condizioni economiche di favore
                      riservate agli associati.
                    </li>
                    <li>
                      Partecipazione ad altre
                      <strong> iniziative culturali e ricreative </strong>organizzate
                      dall'associazione con la finalità di
                      <strong> sviluppare il network.</strong>
                    </li>
                    <li>
                      Partecipazione ad{" "}
                      <strong>
                        eventi organizzati da IPLS International Partnering in
                        Lifescience Society e dalle altre associate EU.
                      </strong>
                    </li>
                    <li>
                      <strong>
                        Abbonamento al Business Development & Licensing Journal.
                      </strong>
                    </li>
                  </ul>
                  {/* Button */}
                  
                
                  <div className="w-full mt-6 flex justify-start items-center">
                    <a
                      className="w-[80%] px-20 py-6 bg-[#12357a] text-white text-center text-xs"
                      href="/iscrizione"
                    >
                      ISCRIZIONE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MobileMenu/>
      <Footer></Footer>
    </>
  );
}

export default Login;

import React, { memo } from "react";

interface Member {
  sys: { id: string };
  media_url: string;
  fields: {
    fullName: string;
    linkedinProfile: string;
    company: string;
    role: string;
  };
}

interface ConsiglioProps {
  cons: Member[] | null;
  inIcon: string;
}

const Consiglio: React.FC<ConsiglioProps> = ({ cons, inIcon }) => {
  return (
    <>
      {cons && (
        <>
          <h1 className="text-[#00a695] text-2xl font-bold px-24 mt-24">
            Consiglio Direttivo
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pt-16">
            {cons.map((member) => (
              <div key={member.sys.id} className="pb-12">
                <div className="thumb">
                  <a
                    href={member.fields.linkedinProfile}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={member.media_url}
                      alt={`${member.fields.fullName}'s Profile Picture`}
                      className="mb-8"
                    />
                    <img
                      src={inIcon}
                      alt="LinkedIn Icon"
                      className="linkedin"
                    />
                  </a>
                </div>
                <h2 className="text-[21px] font-normal">{member.fields.fullName}</h2>
                <p className="text-[21px] font-normal  opacity-50 pb-1">
                  {member.fields.company}
                </p>
                <p className="text-[#00a694] text-[21px] font-normal  italic leading-tight">
                  {member.fields.role}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default memo(Consiglio);

import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

import { getDatabase, ref, get, set } from "firebase/database";

import { AuthContext } from "../context/UserContext";

import config from '../config/index.json';
import heroBackgroundImage from "../images/hero.jpg"
import ResponsiveHeader from "../components/ResponsiveHeader";
import ReservedSideMenu from "../components/ReservedSideMenu";

interface UserField {
  name: string;
  surname: string;
  email: string;
  password: string;
  cpassword: string;
  telephone: string;
  address: string;
  cap: string;
  city: string;
  province: string;
  nation: string;
  tax_id: string;
  gender: string;
  city_of_birth: string;
  date_of_birth: string;
  agency: string;
  role: string;
  company_postcode: string;
  company_province: string;
  company_nation: string;
}

function ReservedArea() {
  const { images } = config
  const { logo } = images
  const { user, logOut, loading } = useContext(AuthContext);
  const [genderMessage, setGenderMessage] = useState(false);
  const { userInfo:userData } = useContext(AuthContext);

  const navigate = useNavigate();

  const getProfile = () => {
    const db = getDatabase();
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.target;

    const db = getDatabase();
    if (form["gender"].value == "Sesso") {
      setGenderMessage(true);
      document.getElementById("gender")?.click();
    }
    set(ref(db, "users/" + user.uid), {
      name: form["name"]?.value,
      surname: form["surname"]?.value,
      email: form["email"]?.value,
      telephone: form["telephone"]?.value,
      address: form["address"]?.value,
      cap: form["cap"]?.value,
      city: form["city"]?.value,
      province: form["province"]?.value,
      nation: form["nation"]?.value,
      tax_id: form["tax_id"]?.value,
      gender: form["gender"]?.value,
      city_of_birth: form["city_of_birth"]?.value,
      date_of_birth: form["date_of_birth"]?.value,
      agency: form["agency"]?.value,
      role: form["role"]?.value,
      company_address: form["company_address"]?.value,
      company_city: form["company_city"]?.value,
      company_postcode: form["company_postcode"]?.value,
      company_province: form["company_province"]?.value,
      company_nation: form["company_nation"]?.value,
    })
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        console.error("setdata", error);
      });
  };

  useEffect(()=>{
    getProfile();
  },[user])

  const onClickLogout = () => {
    logOut();
    navigate('/')
  }

  return (
    <div className="bg-[#effbff]">
      {/* <section
        id="hero"
        className="bg-hero brightness-100 bg-no-repeat w-3/5 bg-center bg-cover pt-80 pb-6 h-[40vh]"
      >
        <div
          id="header"
          className="absolute w-full top-0 z-20 pt-4 md:pt-16 pb-4 px-4 md:px-16"
        >
          <div className="flex justify-center md:justify-between flex-col-reverse	md:flex-row">
            <div className="flex items-left md:items-center">
              <img
                src={logo}
                className="mt-16 md:my-4 w-40 block mr-auto ml-0 md:mx-auto hover:cursor-pointer"
                alt="logo"
                onClick={handleClickLogo}
              />
            </div>
          </div>
        </div>
      </section> */}
      <ResponsiveHeader notFull={true} backgroundImage={heroBackgroundImage} title="Area riservata" />
      {/* <SimpleHeader/> */}
      <section>
        <div className="pb-[200px]">
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className="hidden md:block col-span-2 pt-[100px] pl-[60px] md:pr-[20px] lg:pr-[60px]">
                <>
                  <h1 className="text-lg font-bold mb-2">{`${userData?.name ?? ''} ${userData?.surname ?? ''}`}</h1>
                  <hr className="color-[#00a695] border border-[#00a695] w-4/5" />
                  <a href="/documenti">
                    <div className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a594] hover:underline">
                      Archivio documenti riservati
                    </div>
                  </a>
                  <a href="/elenco-associati">
                    <div className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a594] hover:underline">
                      Elenco associati
                    </div>
                  </a>
                  <div className="text-lg text-[#242424]  mt-2 font-[800]">
                    Il mio profilo
                  </div>
                  <div onClick={()=>onClickLogout()} className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a594] hover:underline hover:cursor-pointer">
                    Esci
                  </div>
                  <hr className="color-[#00a695] border border-[#00a695] w-4/5 mt-80" />
                  <div className="text-lg text-[#242424] font-semibold mt-2">
                    Hai bisogno di aiuto?
                  </div>
                  <a href="mailto:info@italyhlg.it" className="text-lg font-semibold text-[#00a695] mt-2 underline">
                    info@italyhlg.it
                  </a>
                </>
            </div>

            <div id="" className="col-span-3 z-10 mt-[-120px]">
              <div id="form" className="z-50 bg-[#effbff] md:p-24 pt-8 px-16">
                <div className="hidden md:block text-5xl text-[#0c2957] font-[700]">
                  <h1 className="mb-6">Area Riservata</h1>
                </div>
                <p className="my-10 font-semibold text-lg text-[#242424]">
                  In questa sezione sono disponibili la lista degli iscritti ad Italy HLG, i contributi dei relatori che partecipano ad eventi e webinar da noi promossi, le presentazioni delle Assemblee, la collezione delle uscite del Business Development & Licensing Journal.
                </p>
                <ReservedSideMenu selectedMenuIndex={2}/>
                <a id="GoToForm" href="#form"></a>
                <a id="GoToGender" href="#gender"></a>
                <form className="mt-6 w-full md:w-[80%] font-semibold" onSubmit={handleSubmit} target="_blank">
                  <h1 className="text-[28px] text-[#0c2957] mb-8 font-bold">Il mio profilo</h1>
                  <input
                    id="name"
                    name="name"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.name && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Nome"
                    defaultValue={userData?.name}
                  />
                  <input
                    id="surname"
                    name="surname"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.surname && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Cognome"
                    defaultValue={userData?.surname}
                  />
                  <input
                    id="email"
                    name="email"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.email && 'bg-gray-100'}`}
                    type="email"
                    placeholder="E-mail"
                    defaultValue={userData?.email}
                  />

                  <input
                    id="telephone"
                    name="telephone"
                    className={`block w-full p-5 mb-4 mt-20 border border-1 ${!userData?.telephone && 'bg-gray-100'}`}
                    type="phone"
                    placeholder="Telefono"
                    defaultValue={userData?.telephone}
                  />
                  <input
                    id="address"
                    name="address"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.address && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Indirizzo"
                    defaultValue={userData?.address}
                  />
                  <input
                    id="cap"
                    name="cap"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.cap && 'bg-gray-100'}`}
                    type="text"
                    placeholder="CAP"
                    defaultValue={userData?.cap}
                  />
                    <input
                    id="nation"
                    name="nation"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.nation && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Nazione"
                    defaultValue={userData?.nation}
                  />
                  <input
                    id="city"
                    name="city"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.city && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Città"
                    defaultValue={userData?.city}
                  />
                  <input
                    id="province"
                    name="province"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.province && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Provincia"
                    defaultValue={userData?.province}
                  />

                  <input
                    id="tax_id"
                    name="tax_id"
                    className={`block w-full p-5 my-4 mt-20 border border-1 ${!userData?.tax_id && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Codice Fiscale"
                    defaultValue={userData?.tax_id}
                  />
                  <select
                    id="gender"
                    name="gender"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.gender && 'bg-gray-100'}`}
                    defaultValue={userData?.gender}
                  >
                    <option value="man">Maschio</option>
                    <option value="woman">Femmina</option>
                    <option value="woman">Preferisco non dire</option>
                  </select>
                  {genderMessage && (
                    <h1 className="text-red-600">
                      Per favore seleziona il tuo genere
                    </h1>
                  )}
                  <input
                    id="city_of_birth"
                    name="city_of_birth"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.city_of_birth && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Città di nascita"
                    defaultValue={userData?.city_of_birth}
                  />
                  <input
                    id="date_of_birth"
                    name="date_of_birth"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.date_of_birth && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Data di nascita"
                    defaultValue={userData?.date_of_birth}
                  />

                  <input
                    id="agengy"
                    name="agency"
                    type="text"
                    className={`block w-full p-5 my-4 mt-20 border border-1 ${!userData?.agency && 'bg-gray-100'}`}
                    placeholder="Agenzia"
                    defaultValue={userData?.agency}
                  />
                  <input
                    id="role"
                    name="role"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.role && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Ruolo"
                    defaultValue={userData?.role}
                  />
                  <input
                    id="company_address"
                    name="company_address"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.company_address && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Indirizzo Aziendale"
                    defaultValue={userData?.company_address}
                  />
                  <input
                    id="company_postcode"
                    name="company_postcode"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.company_postcode && 'bg-gray-100'}`}
                    type="text"
                    placeholder="CAP Azienda"
                    defaultValue={userData?.company_postcode}
                  />
                  <input
                    id="company_city"
                    name="company_city"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.company_city && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Città Azienda"
                    defaultValue={userData?.company_city}
                  />
                  <input
                    id="company_province"
                    name="company_province"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.company_province && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Provincia Azienda"
                    defaultValue={userData?.company_province}
                  />
                  <input
                    id="company_nation"
                    name="company_nation"
                    className={`block w-full p-5 my-4 border border-1 ${!userData?.company_nation && 'bg-gray-100'}`}
                    type="text"
                    placeholder="Nazione Azienda"
                    defaultValue={userData?.company_nation}
                  />
                  
                  <button
                    className="block w-full p-6 my-4 uppercase text-white text-xs bg-[#00a695]"
                    type="submit"
                  >
                    SALVA
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default ReservedArea;

import React, { useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import config from '../config/index.json';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

AOS.init({
    offset: 50,
    duration: 50,
    easing: 'ease',
    delay: 0,
});

function About() {

    const { images } = config;
    const { plus } = images;

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleClick = (index: number) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <section id='about' className='bg-darkblue'>
            <div className='grid grid-cols-1 md:grid-cols-2 md:flex-row items-center h-full'>
                <div className='pt-16 md:pt-28 pb-8 px-12 md:px-16 md:text-left'>
                    <h3 data-aos="fade-down" className='text-white text-left text-2xl md:text-4xl font-semibold pb-4 md:leading-tight'>Italy Healthcare Licensing Group è l'associazione dei professionisti del licensing e del business development del mercato healthcare.</h3>
                    <p data-aos="fade-down" className='text-white text-2xl leading-loose'>Italy HLG promuove lo sviluppo professionale dei suoi membri attraverso l'organizzazione di corsi, appuntamenti, eventi e seminari.</p>
                    <Link
                        to='chi-siamo'
                        data-aos="fade-down"
                        className='inline-block mt-8 text-white bg-green px-12 py-6 relative text-sm font-semibold cursor-pointer transition hover:transition-all bg-greenHover hover:bg-white hover:text-black btnDemo'>SCOPRI DI PIÙ</Link>
                </div>
                <div id="accordion" className='grid grid-cols-1 grid-flow-row text-center md:text-left bg-lightBlue h-full'>
                    <div className={classnames('grid content-center	item border-b border-black/40 pb-8 pt-8 md:pt-0', { active: activeIndex === 0 })}>
                        <div
                            className='flex flex-row-reverse md:flex-row justify-end gap-8 md:place-content-between items-center height-[25%] cursor-pointer px-12 md:px-16 pt-8'
                            onClick={() => handleClick(0)}    
                        >
                            <h3 className='text-[#0C2957] font-bold text-2xl md:text-4xl'>Perché Italy HLG</h3>
                            <span className='text-6xl font-300 leading-none text-left'><img src={plus} className='w-4 md:w-6' /></span>
                        </div>
                        <div className='accContent md:px-16 px-24 text-left md:text-left'>
                            <p className='text-xl pt-8 pr-0'>Italy HLG promuove lo sviluppo professionale dei suoi membri attraverso l'organizzazione di corsi, appuntamenti, eventi e seminari.</p>
                            <Link
                                to='chi-siamo'
                                className='bg-[#00a695] hover:bg-[#0c2957] inline-block mt-8 text-white bg-green px-12 py-6 relative text-sm font-semibold cursor-pointer transition hover:transition-all btnDemo'>SCOPRI DI PIÙ</Link>
                        </div>
                    </div>
                    <div className={classnames('grid content-center	item border-b border-black/40 pb-8', { active: activeIndex === 1 })}>
                        <div
                            className='flex flex-row-reverse md:flex-row gap-8 justify-end md:place-content-between items-center height-[25%] cursor-pointer px-12 md:px-16 pt-8'
                            onClick={() => handleClick(1)}    
                        >
                            <h3 className='text-[#00A695] font-bold text-2xl md:text-4xl text-left'>Network Internazionale</h3>
                            <span className='text-6xl font-300 leading-none'><img src={plus} className='w-4 md:w-6' /></span>
                        </div>
                        <div className='accContent md:px-16 px-24 text-left md:text-left'>
                            <p className='text-xl pt-8 pr-0'>Italy HLG fa parte di un network internazionale di associazioni di professionisti del Business Development in ambito Healthcare.</p>
                            <Link
                                to='/network-internazionale'
                                className='bg-[#00a695] hover:bg-[#0c2957] inline-block mt-8 text-white bg-green px-12 py-6 relative text-sm font-semibold cursor-pointer transition hover:transition-all btnDemo'>SCOPRI DI PIÙ</Link>
                        </div>
                    </div>
                    <div className={classnames('grid content-center	item border-b border-black/40 pb-8', { active: activeIndex === 2 })}>
                        <div
                            className='flex md:flex-row flex-row-reverse gap-8 justify-end md:place-content-between items-center height-[25%] cursor-pointer px-12 md:px-16 pt-8'
                            onClick={() => handleClick(2)}    
                        >
                            <h3 className='text-[#62C5DE] font-bold text-2xl md:text-4xl text-start'>Aziende e professionisti</h3>
                            <span className='text-6xl font-300 leading-none'><img src={plus} className='w-4 md:w-6' /></span>
                        </div>
                        <div className='accContent md:px-16 px-24 text-left'>
                            <p className='text-xl pt-8 pr-0'>Un gruppo di professionisti che affrontano problematiche comuni e sono animati dalla medesima necessità di un continuativo flusso informativo e formativo.</p>
                            <Link
                                to='/sponsorship'
                                className='bg-[#00a695] hover:bg-[#0c2957] inline-block mt-8 text-white bg-green px-12 py-6 relative text-sm font-semibold cursor-pointer transition hover:transition-all btnDemo'>SCOPRI DI PIÙ</Link>
                        </div>
                    </div>
                    <div className={classnames('grid content-center	item pb-8 md:pb-8', { active: activeIndex === 3 })}>
                        <div
                            className='flex flex-row-reverse md:flex-row justify-end gap-8 md:place-content-between items-center height-[25%] cursor-pointer px-12 md:px-16 pt-8'
                            onClick={() => handleClick(3)}    
                        >
                            <h3 className='text-[#4274BB] font-bold text-2xl md:text-4xl text-left'>Opportunità</h3>
                            <span className='text-2xl md:text-6xl font-300 leading-none'><img src={plus} className='w-4 md:w-6' /></span>
                        </div>
                        <div className='accContent md:px-16 px-24 text-left'>
                            <p className='text-xl pt-8 pr-0'>L'iscrizione a Italy HLG permette di usufruire di numerosi vantaggi, inclusa la possibilità di accedere all'area riservata del sito.</p>
                            <Link
                                to='/job-opportunities'
                                className='bg-[#00a695] hover:bg-[#0c2957] inline-block mt-8 text-white bg-green px-12 py-6 relative text-sm font-semibold cursor-pointer transition hover:transition-all btnDemo'>SCOPRI DI PIÙ</Link>
                        </div>
                    </div>
                    {/* <div className='w-full py-8 bg-[#0c2957]'>

                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default About
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  Asset,
  ContentfulEmbeddedAsset,
  ContentfulLink,
  ContentfulList,
  Page as ContentType,
} from "../types";

import config from "../config/index.json";
import Header from "../components/Header";
import Footer from "../components/Footer";

interface ContentfulParagraph {
  content: ContentfulText[];
  nodeType: "paragraph";
}

interface ContentfulText {
  value: string;
  nodeType: "text";
}

interface ContentfulBody {
  content: (ContentfulParagraph | ContentfulText)[];
  nodeType: "document";
}

interface Job {
  sys: {
    type: string;
    linkType: string;
    id: string;
  };
  fields: {
    title: string;
    dateLastUpdated: string;
    position: string;
    location: string;
    logo: {
      sys: {
        id: string;
      };
    };
  };
}

interface LogoDetails {
  sys: {
    type: string;
    linkType: string;
    id: string;
  };
  fields: {
    file: {
      url: string;
    };
  };
}

interface SuggestedPageDetails {
  sys: {
    type: string;
    linkType: string;
    id: string;
  };
  fields: {
    title: string;
  };
}

interface Page {
  fields: {
    title: string;
    body: ContentfulBody;
    backgroundColor: string;
    primaryColor: string;
    jobs: Job[];
    suggestedPage: {
      sys: {
        id: string;
      };
    };
  };
}

export const slugify = (str: string): string => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

const Jobs = () => {
  const CONTENTFUL_SPACE_ID = "qitjy5hyl5up";
  const CONTENTFUL_ACCESS_TOKEN = "0PL0qCiNnT4GhSRGoDG8E69TrtEvJwipPHfutAMhECU";
  const SINGLEID = "25Fm7r3zJekDf3ano7xCHJ";
  const contentfulEndpoint = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries/${SINGLEID}?access_token=${CONTENTFUL_ACCESS_TOKEN}`;

  const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  const { images } = config;
  const { arrow2black } = images;

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Przewijanie do góry strony
  };

  const [selectedPage, setSelectedPage] = useState<Page | undefined>(undefined);
  const [JobPage, setJobPage] = useState<Job | undefined>(undefined);
  const [PageTitle, setPageTitle] = useState<string | undefined>(undefined);
  const [logoDetails, setLogoDetails] = useState<LogoDetails | undefined>(
    undefined
  );
  const [suggestedPageDetails, setSuggestedPageDetails] = useState<
    SuggestedPageDetails | undefined
  >(undefined);
  const [suggestedPageTitle, setSuggestedPageTitle] = useState<
    string | undefined
  >(undefined);

  const [JobPageTitle, setJobPageTitle] = useState<string | undefined>(
    undefined
  );
  const [LogoURL, setLogoURL] = useState<string | undefined>(undefined);
  const [JobDate, setJobDate] = useState<string | undefined>(undefined);
  const [JobPos, setJobPos] = useState<string | undefined>(undefined);
  const [JobLocation, setJobLocation] = useState<string | undefined>(undefined);

  const fetchJobDetails = async (jobId: string) => {
    try {
      const response = await fetch(
        `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries/${jobId}?access_token=${CONTENTFUL_ACCESS_TOKEN}`
      );
      const jobDetails: Job = await response.json();
      setJobPage(jobDetails);
      setJobPageTitle(jobDetails.fields.title);
      setJobDate(jobDetails.fields.dateLastUpdated);
      setJobPos(jobDetails.fields.position);
      setJobLocation(jobDetails.fields.location);

      // Fetch logo details
      const logoId = jobDetails.fields.logo.sys.id;
      const logoDetailsResponse = await fetch(
        `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/assets/${logoId}?access_token=${CONTENTFUL_ACCESS_TOKEN}`
      );
      const logoDetailsData: LogoDetails = await logoDetailsResponse.json();
      setLogoDetails(logoDetailsData);
      setLogoURL(logoDetailsData.fields.file.url);
    } catch (err) {
      console.error("Error fetching job details:", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries/${SINGLEID}?access_token=${CONTENTFUL_ACCESS_TOKEN}`
        );
        const data: Page = await response.json();
        setSelectedPage(data);
        setPageTitle(data.fields.title);

        // Fetch job details for each job
        data.fields.jobs.forEach((job: Job) => {
          fetchJobDetails(job.sys.id);
        });

        // Fetch suggested details
        const suggestedPageId = data.fields.suggestedPage.sys.id;
        const suggestedPageResponse = await fetch(
          `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries/${suggestedPageId}?access_token=${CONTENTFUL_ACCESS_TOKEN}`
        );
        const suggestedPageData: SuggestedPageDetails =
          await suggestedPageResponse.json();
        setSuggestedPageDetails(suggestedPageData);
        setSuggestedPageTitle(suggestedPageData.fields.title);
      } catch (err) {
        console.error("Error fetching page data:", err);
      }
    };

    fetchData();
  }, []);

  if (!selectedPage) {
    return <div>Loading...</div>;
  }

  const { body, backgroundColor, primaryColor, jobs } = selectedPage.fields;

  const formatDate = (JobDate: string) => {
    const dateObject = new Date(JobDate);
    const day = dateObject.getDate();
    const month = months[dateObject.getMonth()];
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`;
  };
  const formattedDate = JobDate ? formatDate(JobDate) : "";

  const slugify = (str: string): string => {
    return str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  };
  const jobSlug = JobPageTitle ? slugify(JobPageTitle) : "";
  const suggestedSlug = suggestedPageTitle ? slugify(suggestedPageTitle) : "";

  return (
    <div>
      <Header />
      <section
        id="hero"
        className="pt-72 pb-36"
        style={{ background: `#${backgroundColor}`, color: `#${primaryColor}` }}
      >
        <div className="flex flex-col md:flex-row px-8 md:px-16 items-center h-full">
          <div className="basis-8/12 md:pr-16 text-center md:text-left">
            <h3
              data-aos="fade"
              className="font-300 text-5xl max-w-[360px] leading-snug pb-32"
            >
              Opportunità di lavoro, crescita e sviluppo
            </h3>
          </div>
        </div>

        <div className="flex flex-col md:flex-row px-8 md:px-16 h-full">
          <div className="basis-4/12 md:pr-16 text-center md:text-left relative">
            <h1
              id="title"
              data-aos="fade"
              className="text-center md:text-left text-2xl uppercase font-semibold pb-4 md:leading-tight absolute"
            >
              JOB OPPORTUNITIES
            </h1>
          </div>
          <div
            className="basis-6/12 text-center md:text-left text-md"
            data-aos="fade"
          >
            {body.content.map((content, index) => {
              if (content.nodeType === "paragraph") {
                const paragraphContent = content.content as ContentfulText[];
                const paragraphText = paragraphContent
                  .map((node) => node.value)
                  .join(" ");
                return (
                  <p key={index} style={{ color: `#${primaryColor}` }}>
                    {paragraphText}
                  </p>
                );
              } else if (content.nodeType === "text") {
                return (
                  <p key={index} style={{ color: `#${primaryColor}` }}>
                    {content.value}
                  </p>
                );
              }
              return null;
            })}
            <div style={{ color: `#${primaryColor}` }}>
              {jobs.map((job, index) => (
                <div key={index} className="contentBlock mt-24">
                  <div
                    className="text-[#00a69a] font-semibold"
                    data-aos="fade"
                  >
                    {formattedDate}
                  </div>
                  <br />
                  <div
                    className="max-w-[240px] shadow-md mb-8"
                    data-aos="fade"
                  >
                    <img src={LogoURL} />
                  </div>
                  <div
                    className="text-3xl font-medium text-black pb-6"
                    data-aos="fade"
                  >
                    {JobPageTitle}
                  </div>
                  <div
                    className="text-black text-base pb-4"
                    data-aos="fade"
                  >
                    <strong className="font-semibold">Posizione: </strong>
                    {JobPos}
                  </div>
                  <div
                    className="text-black text-base pb-4"
                    data-aos="fade"
                  >
                    <strong className="font-semibold">Location: </strong>
                    {JobLocation}
                  </div>
                  <div data-aos="fade">
                    <Link
                      to={`/job?id=${jobSlug}`}
                      className="btn text-white bg-[#00a695] hover:bg-[#00bdaa] transition text-base font-semibold px-12 py-6 block mt-6"
                    >
                      SCOPRI DI PIÚ
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          data-aos="fade-left"
          className="pageFoot flex place-content-end pt-36 pb-12 px-24 dark"
        >
          <Link
            to={"/" + suggestedSlug}
            className="flex text-white text-4xl hover:border-black"
            style={{ color: `#${primaryColor}` }}
            onClick={handleLinkClick}
          >
            Continua con{" "}
            <strong className="font-semibold">{suggestedPageTitle}</strong>
            <img src={arrow2black} className="w-8 ml-6" />
          </Link>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Jobs;

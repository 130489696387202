import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../index.css";

import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import LazyShow from "../components/LazyShow";
import Header from "../components/Header";
import MailchimpFormNews from "../components/MailchimpFormContainerNews";
import MobileHeader from "../components/MobileHeader";
import MobileMenu from "../components/MobileMenu";
import Hero from "../components/Hero";

function NewsList() {
  const navigate = useNavigate()
  const [ totalNews, setTotalNews ] = useState<any>([])
  const [ displayedNews, setDisplayedNews ] = useState(10);

  const CONTENTFUL_SPACE_ID = "qitjy5hyl5up"
  const CONTENTFUL_ACCESS_TOKEN = "TKo1zCQL_v1NQmGcRQXQVqayvDSqukrdCeRlbpEedyM"

  const contentfulEndpointNews = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=news&order=-fields.date`;
  const [marginVideo, setMarginVideo] = useState(window.innerWidth);

  const handleResize = () => {
    const heightOfVideo = 0.47916*window.innerWidth;
    let marginVideo = 0;
    if(window.innerWidth>754)
    {
      marginVideo = Math.floor(heightOfVideo-380);
    } 
    setMarginVideo(marginVideo);
  };

  useEffect(()=>{
    const heightOfVideo = 0.47916*window.innerWidth;
    let marginVideo = 0;
    if(window.innerWidth>754)
    {
      marginVideo = Math.floor(heightOfVideo-380);
    } 
    setMarginVideo(marginVideo);
    window.addEventListener('resize', handleResize);
    (async () => {
      try {
        const res = await fetch(contentfulEndpointNews);
        const data = await res.json();
        setTotalNews(data);
      } catch (error) {
        console.error(error);
      }
    })();

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  },[])



  const handleLoadMore = () => {
    const totlaLength = totalNews?.items?.length ?? 0;
    if (displayedNews+10>=totlaLength) 
    {
      setDisplayedNews(totlaLength);
    } else {
      setDisplayedNews(displayedNews+10);
    }
  }

  const handleSelectNews = (news:any) => {
    navigate(`/news?title=${news.fields.slug}&id=${news?.sys.id}`)
  }

  return (
    <>
      <Header />
      <MobileHeader/>
      
      <div id="header" className='absolute w-full top-0 flex justify-end z-20 pt-4 md:pt-16 pb-4 px-4 md:px-16'/>
      <LazyShow>
        <div style={{marginTop:`${-marginVideo}px`}}>
          <section id='hero' className='bg-hero bg-no-repeat bg-center bg-cover relative h-full md:h-auto top-0'>
            <video className='hidden md:block' autoPlay loop muted > 
                <source className='hidden md:block' src={require('../videos/home.mp4')} type='video/mp4'/>
            </video>
          </section>
          <section id='hero' className='md:hidden bg-hero bg-no-repeat bg-center bg-cover relative h-auto lg:mt-[-300px] md:mt-[-150px]'>
            <video autoPlay loop muted className='w-full'> 
              <source src={require('../videos/news.mp4')} type='video/mp4'/>
            </video>
            <div className="text-4xl text-white absolute left-[60px] bottom-[150px] z-[101]">
              News
            </div>
          </section>
        </div>
      </LazyShow>
      <section>
        <div className="pb-[150px] md:pb-[250px]">
          <div className="grid grid-cols-1 md:grid-cols-3">
            <div className="col-span-1 pt-10 pl-10 hidden md:block">
                <h1 className="text-[#0c2957] text-[2rem] font-bold mb-10 ms-8">News</h1>
                <div className="bg-[#e0f9fd] w-full pt-12 pb-10 pl-10 pr-16">
                    <h1 className="w-[200px] text-3xl text-[#0c2957] font-bold mb-6">
                        Iscriviti alla newsletter
                    </h1>
                    <p className="text-lg font-semibold">
                        Iscriviti per ricevere periodicamente notizie e aggiornamenti sul mondo 
                        di Italy HLG, incluse le nostre iniziative in anteprima, i nostri eventi 
                        e quello che nella nostra industria ci ispira e ci guida. Iscrivendoti 
                        accetti <span>questi termini.</span>
                    </p>
                    <MailchimpFormNews/>
                </div>
            </div>
            <div className="col-span-2 mt-[-100px] z-10">
              <div className=" z-50 bg-white pt-[100px] md:pt-[155px] md:pl-[120px] md:pr-40">
              {totalNews?.items?.slice(0, displayedNews).map((news:any, index:number)=>{
                const partsDate = news?.fields.date.split('-');
                const formatedDate = `${partsDate[2]}/${partsDate[1]}/${partsDate[0]}`
                return(
                  <>
                    <div onClick={()=>handleSelectNews(news)} className="mb-20 px-[60px] cursor-pointer">
                      <h3 className="text-[#27b4a5] text-lg  md:text-xs pb-6 md:pb-2">{formatedDate}</h3>
                      <h1 className="text-[2rem] font-bold md:font-normal text-[#0c2957] leading-[3rem] hover:underline mb-0">{news?.fields.title}</h1>
                      <p className="text-lg text-[#0c2957] mb-4 line-clamp-4  ">
                        {
                          news?.fields.newsBody.content.map((desc:any, index:number)=>{
                            return (
                              <p key={index} className="mt-6 text-md font-[600]">
                              {
                                desc?.content.map((p:any, indexp:number)=>{
                                  return (
                                    <>                            
                                      {p?.content && 
                                        <>
                                          {p?.nodeType == "hyperlink" ?
                                          <a className="text-[#00a695] hover:underline hover:cursor-pointer" href={p?.data.uri}>{p.content?.value || p?.content[0]?.value}</a> 
                                          :
                                          <p>{p.content?.value}</p> 
                                          }
                                        </>
                                      }
                                      <span className={(p.marks && p.marks[0]?.type=='bold')?'font-bold':'font-normal'}>{p.value}</span>
                                    </>
                                  )
                                })
                              }
                              </p>
                            )
                          })
                        }
                      </p>
                      <h3 className="text-[#27b4a5] hover:underline font-[600] text-xl md:text-lg">Leggi di più</h3>
                    </div>
                    {index==2 &&<div className="col-span-1 pt-10 md:hidden">
                        <div className="bg-[#e0f9fd] w-full pt-12 pb-10 pl-10 pr-16 mb-16">
                            <h1 className="w-[full] text-3xl text-[#0c2957] font-bold mb-6">
                                Iscriviti alla newsletter
                            </h1>
                            <p className="text-lg font-semibold">
                                Iscriviti per ricevere periodicamente notizie e aggiornamenti sul mondo 
                                di Italy HLG, incluse le nostre iniziative in anteprima, i nostri eventi 
                                e quello che nella nostra industria ci ispira e ci guida. Iscrivendoti 
                                accetti <span className="underline">questi termini.</span>
                            </p>
                            <MailchimpFormNews/>
                        </div>
                    </div>}
                  </> 
                )
              })     
              } 
              <div onClick={handleLoadMore} className="bg-[#b0d8ff] hover:cursor-pointer py-4 text-white md:hidden mx-[60px] text-center">
                LOAD MORE
              </div>
              </div>
            </div>
          </div>
        </div>
        <MobileMenu/>

      </section>
      <Footer></Footer>
    </>
  );
}

export default NewsList;

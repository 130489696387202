import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Asset,
  ContentfulEmbeddedAsset,
  ContentfulParagraph,
  ContentfulText,
  ContentfulLink,
  ContentfulList,
  Page,
} from "../types";

import config from "../config/index.json";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MobileHeader from "../components/MobileHeader";

interface ContentfulResponse {
  items: Page[];
  includes: {
    Asset: Asset[];
  };
}

AOS.init({
  offset: 50,
  duration: 120,
  easing: "ease",
  delay: 0,
});

const CONTENTFUL_SPACE_ID = "qitjy5hyl5up";
const CONTENTFUL_ACCESS_TOKEN = "0PL0qCiNnT4GhSRGoDG8E69TrtEvJwipPHfutAMhECU";
const contentfulEndpoint = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=page`;

export const slugify = (str: string): string => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

const handleLinkClick = () => {
  window.scrollTo(0, 0); // Przewijanie do góry strony
};

const SinglePage = () => {
  const { pageSlug } = useParams();
  const [selectedPage, setSelectedPage] = useState<Page | undefined>(undefined);
  const [assets, setAssets] = useState<Asset[] | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [suggestedPageTitle, setSuggestedPageTitle] = useState<
    string | undefined
  >(undefined);
  const { images } = config;
  const { arrow2 } = images;

  useEffect(() => {
    (async () => {
      if (selectedPage?.fields.suggestedPage.sys.id) {
        try {
          const suggestedPageId = selectedPage?.fields.suggestedPage.sys.id;
          const response = await fetch(
            `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries/${suggestedPageId}?access_token=${CONTENTFUL_ACCESS_TOKEN}`
          );
          const data = await response.json();
          setSuggestedPageTitle(data.fields.title);
        } catch (err) {
          console.error("Error fetching suggested page title:", err);
        }
      }
    })();
    console.log(selectedPage?.fields.title)
  }, [selectedPage]);

  useEffect(() => {
    (async () => {
      await fetch(contentfulEndpoint)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          setSelectedPage(
            (res as unknown as ContentfulResponse).items.find(
              (page: Page) => slugify(page.fields.title) == pageSlug
            )
          );
          setAssets((res as unknown as ContentfulResponse).includes.Asset);
        })
        .catch((err) => {
          console.error(err);
          setError("Failed to fetch pages. Please try again later.");
        });
    })();
  }, [pageSlug]);

  if (!setSelectedPage) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />
      <MobileHeader/>
      {selectedPage && assets && suggestedPageTitle && (
        <>
          <section
            id="hero"
            className="pt-48 px-0 md:pt-72 pb-36"
            style={{
              background: "#" + selectedPage.fields.backgroundColor,
              color: "#" + selectedPage.fields.primaryColor,
            }}
          >
            <div className="flex flex-col md:flex-row md:px-16 items-center h-full">
              <div className="px-8 w-full text-left basis-8/12 md:pr-16">
                <h3
                  data-aos="fade"
                  className="font-[300] md:font-normal text-white text-5xl max-w-[480px] leading-snug pb-16"
                >
                  {selectedPage.fields.summary.content.map((content) => {
                    if (content.nodeType === "paragraph") {
                      const paragraphContent = (content as ContentfulParagraph)
                        .content;

                      // Collect and join the content values
                      const paragraphText = paragraphContent
                        .map((node) => {
                          if (node.nodeType === "text") {
                            return (node as ContentfulText).value;
                          }
                          return "";
                        })
                        .join("");

                      return paragraphText; // Return the joined content
                    }
                    return ""; // Handle other content types or return an empty string
                  })}
                </h3>
              </div>
            </div>
            { ['Chi Siamo', 'Board', 'Sponsorship'].includes(selectedPage.fields.title) ?
              <>
                <div className="md:hidden text-[28px] flex flex-col gap-4 px-8 md:px-16 mb-24 relative z-[50]">
                  {
                    selectedPage.fields.title=='Chi Siamo' ?
                      <h1 className={`font-normal`}>Chi siamo</h1>
                      :
                      <Link className="w-fit" to="/chi-siamo"><h1 className={`font-normal underline`}>Chi siamo</h1></Link>
                  }
                  {
                    selectedPage.fields.title=='Board' ?
                      <h1 className={`font-normal`}>Board</h1>
                      :
                      <Link className="w-fit" to="/board"><h1 className={`font-normal underline`}>Board</h1></Link>
                  }
                  {
                    selectedPage.fields.title=='Sponsorship' ?
                      <h1 className={`font-normal`}>Sponsorship</h1>
                      :
                      <Link className="w-fit" to="/sponsorship"><h1 className={`font-normal underline`}>Sponsorship</h1></Link>
                  }
                </div>
              </>
              :
              <>
                { selectedPage?.fields.title == "BD Academy" ?
                  <></>
                  :
                  <div className="md:hidden text-[28px] flex flex-col gap-4 px-8 mb-24 relative z-[50]">
                    {
                      selectedPage.fields.title=='Network internazionale' ?
                        <h1 className={`font-normal`}>Network Internazionaleo</h1>
                        :
                        <Link className="w-fit" to="/network-internazionale"><h1 className={`font-normal underline`}>Network Internazionaleo</h1></Link>
                    }
                    {
                      selectedPage.fields.title=='IPLS' ?
                        <h1 className={`font-normal`}>IPLS</h1>
                        :
                        <Link className="w-fit" to="/ipls"><h1 className={`font-normal underline`}>IPLS</h1></Link>
                    }
                  </div>
                }
              </>
            }
            
            <div className="hidden md:flex flex-col md:flex-row px-8 md:px-16 items-center h-full">
              <div className="basis-8/12 px-8 md:pr-16 text-center md:text-left relative">
                <h1
                  id="title"
                  data-aos="fade"
                  className="text-white text-center md:text-left text-2xl uppercase font-semibold pb-4 md:leading-tight absolute"
                >
                  {selectedPage.fields.title}
                </h1>
              </div>
            </div>
            <div className="contentAreaMobile md:hidden px-8 md:px-16">
              {selectedPage.fields.body.content.map(
                (content, index, array) => {
                  let renderedContent = null;

                  if (content.nodeType === "paragraph") {
                    const paragraphContent = (content as ContentfulParagraph)
                      .content;
                    renderedContent = paragraphContent.map(
                      (node, nodeIndex) => {
                        if (node.nodeType === "text") {
                          if (
                            node.marks.some((mark) => mark.type === "bold")
                          ) {
                            return (
                              <span key={nodeIndex}>
                                <strong>
                                  {(node as ContentfulText).value}
                                </strong>
                              </span>
                            );
                          } else {
                            return (
                              <span key={nodeIndex} className="text-[21px] my-8">
                                {(node as ContentfulText).value}
                              </span>
                            );
                          }
                        } else if (node.nodeType === "hyperlink") {
                          const linkContent = (node as ContentfulLink)
                            .content;
                          let linkText = "";

                          if (Array.isArray(linkContent)) {
                            linkText = linkContent
                              .map((textNode) => {
                                if (textNode.nodeType === "text") {
                                  return (textNode as ContentfulText).value;
                                }
                                return "";
                              })
                              .join("");
                          }

                          const linkUri = (node as ContentfulLink).data.uri;

                          return (
                            <a key={nodeIndex} href={linkUri} target="_blank">
                              {linkText}
                            </a>
                          );
                        } else {
                          return null;
                        }
                      }
                    );
                  } else if (content.nodeType === "blockquote") {
                    return (
                      <blockquote
                        key={index}
                        data-aos="fade"
                        style={{
                          color: "#" + selectedPage.fields.secondaryColor,
                        }}
                      >
                        {(content as ContentfulText).value}
                      </blockquote>
                    );
                  } else if (content.nodeType === "heading-1") {
                    return (
                      <h3 key={index} data-aos="fade">
                        {(content as ContentfulText).value}
                      </h3>
                    );
                  } else if (content.nodeType === "embedded-asset-block") {
                    // Check if the previous or next node is not embedded-asset-block
                    const isStart =
                      index === 0 ||
                      array[index - 1]?.nodeType !== "embedded-asset-block";
                    const isEnd =
                      index === array.length - 1 ||
                      array[index + 1]?.nodeType !== "embedded-asset-block";

                    // Determine the class based on the position and surrounding nodes
                    let imgNumber = 1;
                    if (!isStart) {
                      let prevIndex = index - 1;
                      while (
                        prevIndex >= 0 &&
                        array[prevIndex]?.nodeType === "embedded-asset-block"
                      ) {
                        imgNumber++;
                        prevIndex--;
                      }
                    }
                    const divClass = `img${imgNumber
                      .toString()
                      .padStart(2, "0")} ${imgNumber==2?'hidden':' py-8'}`;

                    return (
                      <div
                        data-aos="fade"
                        className={`gallery ${divClass}`}
                        key={index}
                      >
                        <img
                          src={
                            assets.find(
                              (asset) =>
                                asset.sys.id ===
                                (content as ContentfulEmbeddedAsset).data
                                  .target.sys.id
                            )?.fields.file.url
                          }
                          alt=""
                        />
                      </div>
                    );
                  } else if (content.nodeType === "unordered-list") {
                    const ulContent = (content as ContentfulList).content;
                    return (
                      <ul className="list-disc ps-8 py-8" data-aos="fade" key={index}>
                        {ulContent.map((listItem, listItemIndex) => {
                          if (
                            "content" in listItem &&
                            Array.isArray(listItem.content) &&
                            listItem.content.length > 0 &&
                            "content" in listItem.content[0] &&
                            Array.isArray(listItem.content[0].content) &&
                            listItem.content[0].content.length > 0 &&
                            "value" in listItem.content[0].content[0]
                          ) {
                            const value = (
                              listItem.content[0].content[0] as ContentfulText
                            ).value;
                            return <li key={listItemIndex}>{value}</li>;
                          } else {
                            // Handle cases where the structure doesn't match the expected pattern
                            return null;
                          }
                        })}
                      </ul>
                    );
                  }
                  return <p data-aos="fade">{renderedContent}</p>;
                }
              )}
              {selectedPage.fields.body.content.map(
                    (content, index, array) => {
                      if (content.nodeType === "embedded-asset-block") {
                        // Check if the previous or next node is not embedded-asset-block
                        const isStart =
                          index === 0 ||
                          array[index - 1]?.nodeType !== "embedded-asset-block";
                        const isEnd =
                          index === array.length - 1 ||
                          array[index + 1]?.nodeType !== "embedded-asset-block";
      
                        // Determine the class based on the position and surrounding nodes
                        let imgNumber = 1;
                        if (!isStart) {
                          let prevIndex = index - 1;
                          while (
                            prevIndex >= 0 &&
                            array[prevIndex]?.nodeType === "embedded-asset-block"
                          ) {
                            imgNumber++;
                            prevIndex--;
                          }
                        }
                        const divClass = `img${imgNumber
                          .toString()
                          .padStart(2, "0")} ${imgNumber!=2?'hidden':'py-8'}`;
      
                        return (
                          <div
                            data-aos="fade"
                            className={`gallery ${divClass}`}
                            key={index}
                          >
                            <img
                              src={
                                assets.find(
                                  (asset) =>
                                    asset.sys.id ===
                                    (content as ContentfulEmbeddedAsset).data
                                      .target.sys.id
                                )?.fields.file.url
                              }
                              alt=""
                            />
                          </div>
                        );
                      }
                    })
                  } 
            </div>
  
            <div className="hidden contentArea md:flex flex-col md:flex-row items-center w-full">
              <div className="text-center md:text-left">
                {selectedPage.fields.body.content.map(
                  (content, index, array) => {
                    let renderedContent = null;

                    if (content.nodeType === "paragraph") {
                      const paragraphContent = (content as ContentfulParagraph)
                        .content;
                      renderedContent = paragraphContent.map(
                        (node, nodeIndex) => {
                          if (node.nodeType === "text") {
                            if (
                              node.marks.some((mark) => mark.type === "bold")
                            ) {
                              return (
                                <span key={nodeIndex}>
                                  <strong>
                                    {(node as ContentfulText).value}
                                  </strong>
                                </span>
                              );
                            } else {
                              return (
                                <span key={nodeIndex}>
                                  {(node as ContentfulText).value}
                                </span>
                              );
                            }
                          } else if (node.nodeType === "hyperlink") {
                            const linkContent = (node as ContentfulLink)
                              .content;
                            let linkText = "";

                            if (Array.isArray(linkContent)) {
                              linkText = linkContent
                                .map((textNode) => {
                                  if (textNode.nodeType === "text") {
                                    return (textNode as ContentfulText).value;
                                  }
                                  return "";
                                })
                                .join("");
                            }

                            const linkUri = (node as ContentfulLink).data.uri;

                            return (
                              <a key={nodeIndex} href={linkUri} target="_blank">
                                {linkText}
                              </a>
                            );
                          } else {
                            return null;
                          }
                        }
                      );
                    } else if (content.nodeType === "blockquote") {
                      return (
                        <blockquote
                          key={index}
                          data-aos="fade"
                          style={{
                            color: "#" + selectedPage.fields.secondaryColor,
                          }}
                        >
                          {(content as ContentfulText).value}
                        </blockquote>
                      );
                    } else if (content.nodeType === "heading-1") {
                      return (
                        <h3 key={index} data-aos="fade">
                          {(content as ContentfulText).value}
                        </h3>
                      );
                    } else if (content.nodeType === "embedded-asset-block") {
                      // Check if the previous or next node is not embedded-asset-block
                      const isStart =
                        index === 0 ||
                        array[index - 1]?.nodeType !== "embedded-asset-block";
                      const isEnd =
                        index === array.length - 1 ||
                        array[index + 1]?.nodeType !== "embedded-asset-block";

                      // Determine the class based on the position and surrounding nodes
                      let imgNumber = 1;
                      if (!isStart) {
                        let prevIndex = index - 1;
                        while (
                          prevIndex >= 0 &&
                          array[prevIndex]?.nodeType === "embedded-asset-block"
                        ) {
                          imgNumber++;
                          prevIndex--;
                        }
                      }
                      const divClass = `img${imgNumber
                        .toString()
                        .padStart(2, "0")}`;

                      return (
                        <div
                          data-aos="fade"
                          className={`gallery ${divClass}`}
                          key={index}
                        >
                          <img
                            src={
                              assets.find(
                                (asset) =>
                                  asset.sys.id ===
                                  (content as ContentfulEmbeddedAsset).data
                                    .target.sys.id
                              )?.fields.file.url
                            }
                            alt=""
                          />
                        </div>
                      );
                    } else if (content.nodeType === "unordered-list") {
                      const ulContent = (content as ContentfulList).content;
                      return (
                        <ul data-aos="fade" key={index}>
                          {ulContent.map((listItem, listItemIndex) => {
                            if (
                              "content" in listItem &&
                              Array.isArray(listItem.content) &&
                              listItem.content.length > 0 &&
                              "content" in listItem.content[0] &&
                              Array.isArray(listItem.content[0].content) &&
                              listItem.content[0].content.length > 0 &&
                              "value" in listItem.content[0].content[0]
                            ) {
                              const value = (
                                listItem.content[0].content[0] as ContentfulText
                              ).value;
                              return <li key={listItemIndex} style={{marginLeft:-200}}>{value}</li>;
                            } else {
                              // Handle cases where the structure doesn't match the expected pattern
                              return null;
                            }
                          })}
                        </ul>
                      );
                    }

                    return <p data-aos="fade" style={{paddingLeft:0, paddingRight:0, marginLeft:'40%'}}>{renderedContent}</p>;
                  }
                )}
              </div>
            </div>

            <div
              data-aos="fade-left"
              className="pageFoot flex place-content-end pt-36 pb-12 px-16 md:px-24"
            >
              <Link
                to={"/" + slugify(suggestedPageTitle)}
                className="flex text-white text-xl md:text-4xl"
                onClick={handleLinkClick}
              >
                <strong className="font-semibold">{`Continua con ${suggestedPageTitle}`}</strong>
                <img src={arrow2} className="w-6 md:w-8 ml-6" />
              </Link>
            </div>
          </section>
        </>
      )}
      <Footer />
    </div>
  );
};

export default SinglePage;
import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../context/UserContext";

import ResponsiveHeader from "../components/ResponsiveHeader";

import HeroBackgroundImage from "../images/hero.jpg";
import ReservedSideMenu from "../components/ReservedSideMenu";

const CONTENTFUL_SPACE_ID = "qitjy5hyl5up"
const CONTENTFUL_ACCESS_TOKEN = "TKo1zCQL_v1NQmGcRQXQVqayvDSqukrdCeRlbpEedyM"  
const contentfulEndpointCollectors = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=documentCollector&order=-fields.date`;
const contentfulEndpointCategories = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=documentCategory`;

const displayPerLoad = 25;

function ReservedAreaDoc() {
  const [ docs, setDocs ] = useState<any>([]);
  const [ docCatetories, setDocCategories ] = useState<any>([]);
  const [ docSubCollections, setDocSubCollections ] = useState<any>([]);
  const [ categoryIndex, setCategoryIndex ] = useState(-1);
  const [ displayedNews, setDisplayedNews ] = useState(displayPerLoad);
  const { user, userInfo, logOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLoadMore = () => {
    const currentDocs = categoryIndex == -1 ? docs : docSubCollections[categoryIndex];
    const currentNews = currentDocs?.length ?? 0;
    if (displayedNews+displayPerLoad>currentNews) {
      setDisplayedNews(currentNews);
    } else {
      setDisplayedNews(displayedNews+displayPerLoad);
    }
  }

  const onClickLogout = () => {
    logOut();
    navigate('/')
  }

  // const handleClickLogo = () => {
  //   navigate('/');
  // }

  const handleCategoryIndex = (index:number) => {
    setCategoryIndex(index);
    setDisplayedNews(displayPerLoad);
  }

  const handleClickDoc = (doc_item:any) => {
    navigate('/mostra-documento', {state: {doc_item: doc_item}})
  }

  useEffect(()=>{
    if(!user?.uid) return;

    (async () => {
        const res = await fetch(contentfulEndpointCollectors)
        const docCollections = await res.json()
        const res1 = await fetch(contentfulEndpointCategories)
        const docCategory = await res1.json();
        setDocCategories(docCategory.items);
        let subCollections:any = [];
        docCategory.items.map((cat:any)=>{
          const subDocCollection = docCollections.items.filter((doc:any) => {
            return doc.fields.category.some((item:any) => item?.sys?.id == cat.sys.id)
            // return doc.fields.catetory?.some((category:any) => category.sys.id==cat.sys.id)
            // return doc.fields.category[0].sys.id==cat.sys.id || doc.fields.category?.[1]?.sys?.id==cat.sys.id
          });
          subCollections = [...subCollections, subDocCollection];
        })
        const unsortedDocs = docCollections.items
        const docs = unsortedDocs.sort((a:any, b:any) => {
          return a.fields.date > b.fields.date
        })
        setDocSubCollections(subCollections);
        setDocs(docs);
    })()

  },[user])

  const currentDocs = categoryIndex == -1 ? docs : docSubCollections[categoryIndex];
  const username = `${userInfo?.name ?? ''} ${userInfo?.surname ?? ''}`
  return (
    <div className="bg-[#effbff]">
      {/* <section
        id="hero"
        className="bg-hero brightness-100 bg-no-repeat w-full bg-center bg-cover pt-80 pb-6 h-[40vh]"
      >
        <div
          id="header"
          className="absolute w-full top-0 z-20 pt-4 md:pt-16 pb-4 px-4 md:px-16"
        >
          <div className="flex justify-center md:justify-between flex-col-reverse	md:flex-row">
            <div className="flex items-left md:items-center">
              <img
                src={logo}
                className="mt-16 md:my-4 w-40 block mr-auto ml-0 md:mx-auto hover:cursor-pointer"
                alt="logo"
                onClick={handleClickLogo}
              />
            </div>
          </div>
        </div>
      </section> */}
      <ResponsiveHeader backgroundImage={HeroBackgroundImage} title="Area Riservata"/>
      <section>
        <div className="pb-[200px]">
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className="hidden md:block col-span-2 pt-[100px] pl-[60px] md:pr-[20px] lg:pr-[60px]">
              <>
                {username && <h1 className="text-lg font-bold mb-2">{username}</h1> }
                <hr className="color-[#00a695] border border-[#00a695] w-4/5" />
                <div className="text-lg text-[#242424] mt-2 font-[800]">
                  Archivio documenti riservati
                </div>
                <a href="/elenco-associati">
                  <div className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a594] hover:underline">
                    Elenco associati
                  </div>
                </a>
                <a href="/profilo">
                  <div className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a695] hover:underline hover:cursor-pointer">
                  Il mio profilo
                  </div>
                </a>
                <div onClick={()=>onClickLogout()} className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a695] hover:underline hover:cursor-pointer">
                  Esci
                </div>
                <hr className="color-[#00a695] border border-[#00a695] w-4/5 mt-80" />
                <div className="text-lg text-[#242424] font-semibold mt-2">
                  Hai bisogno di aiuto?
                </div>
                <a href="mailto:info@italyhlg.it" className="text-lg font-semibold text-[#00a695] mt-2 underline">
                  info@italyhlg.it
                </a>
              </>
            </div>

            <div id="" className="col-span-3 z-10 md:mt-[-120px]">
              <div id="form" className="z-50 bg-[#effbff] px-14 pt-4 md:p-24">
                <div className="text-5xl text-[#0c2957] font-[700]">
                  <h1 className="mb-6 hidden md:block">Area Riservata</h1>
                </div>
                <p className="my-10 font-semibold text-lg text-[#242424] w-full">
                  In questa sezione sono disponibili la lista degli iscritti ad Italy HLG, i contributi dei relatori che partecipano ad eventi e webinar da noi promossi, le presentazioni delle Assemblee, la collezione delle uscite del Business Development & Licensing Journal.
                </p>
                <ReservedSideMenu selectedMenuIndex={0} />
                <a id="GoToForm" href="#form"></a>
                <a id="GoToGender" href="#gender"></a>
                <div className="mt-6 pe-[20px] font-semibold">
                  <h1 className="hidden md:block text-[28px] text-[#0c2957] mb-8 font-bold">Archivio documenti riservati</h1>
                  <div onClick={()=>handleCategoryIndex(-1)} className={`text-sm md:text-lg text-white bg-[#1d5589] p-4 rounded-lg hover:cursor-pointer ${categoryIndex==-1? 'outline' : ''} hover:outline outline-3 outline-[#0c2957]`}>
                    Tutti i documenti &nbsp; ({docs.length})
                  </div>
                  {
                    docCatetories.map((cat:any, index:number)=>{ 
                      return (
                        <div onClick={()=>handleCategoryIndex(index)} className={`text-sm md:text-lg text-white bg-[#99a534] my-4 p-4 rounded-lg ${categoryIndex==index? 'outline' : ''} hover:cursor-pointer hover:outline outline-3 outline-[#0c2957]`} style={{backgroundColor:'#'+cat.fields?.color}}>
                          {cat.fields.name} &nbsp; ({docSubCollections[index].length})
                        </div>
                      )
                    })
                  }

                </div>

                <div className="mt-20 text-lg md:text-2xl text-[#0c2957] font-semibold md:font-bold">
                  {categoryIndex === -1 ? "Tutti i documenti" : (docCatetories[categoryIndex].fields.name || '')}
                </div>

                {currentDocs?.slice(0, displayedNews).map((item:any, index:number)=>{
                  let partDate = item.fields.date.split('-');
                  let formattedDate = `${partDate[1]}/${partDate[2]}/${partDate[0]}`;
                  return (
                    <div key={index} className="mt-8 md:mt-24 text-2xl text-[#0c2957] font-bold">
                      <h1 className="text-[#00a695] text-sm">{formattedDate}</h1>
                      <h1 className="text-[#0c2957] text-2xl md:text-4xl font-bold pt-1 pb-2  md:py-4">{item?.fields?.name} </h1>
                      <h1 className="hidden md:block text-lg font-semibold mb-4">{item?.fields?.shortSummary?.content[0].value ?? item?.fields?.shortSummary?.content[0].content[0]?.value} </h1>
                      <div className="underline md:no-underline text-[#00a695] text-sm md:text-[18px] hover:underline font-semibold hover:cursor-pointer" onClick={()=>handleClickDoc(item)}>Visualizza i materiali</div>
                    </div>
                  )
                })}
                <div onClick={handleLoadMore} className="bg-[#b0d8ff] hover:cursor-pointer py-4 text-white md:hidden mt-16 text-center">
                  LOAD MORE
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default ReservedAreaDoc;

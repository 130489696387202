// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDt1W_AkLFujpu-x1UHLYASkH1R0SSE4L0",
//   authDomain: "ithlg-71409.firebaseapp.com",
//   projectId: "italyhlg-ce2b0",
//   storageBucket: "ithlg-71409.appspot.com",
//   messagingSenderId: "1066803538104",
//   appId: "1:1066803538104:web:9d5c1530d923f1bd5dac4d",
//   measurementId: "G-48JGQCJFZ6",
//   databaseUrl: 'https://console.firebase.google.com/project/ithlg-71409/database/ithlg-71409-default-rtdb/data/~2F'
// };
const firebaseConfig = {
  apiKey: "AIzaSyC4fb0yJG0S00d7_8lVixxIgm_nZxfZCXU",
  authDomain: "italyhlg-ce2b0.firebaseapp.com",
  databaseURL: "https://italyhlg-ce2b0-default-rtdb.firebaseio.com",
  projectId: "italyhlg-ce2b0",
  storageBucket: "italyhlg-ce2b0.appspot.com",
  messagingSenderId: "281832391860",
  appId: "1:281832391860:web:ff6eab3a40df197eec43e1",
  measurementId: "G-J987LBNCV5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export default(app)
import React, {useState, useEffect} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const CustomForm = ({ status, message, onValidated }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 &&
        onValidated(email);

    }

    useEffect(() => {
      if(status === "success") clearFields();
    }, [status])

    const clearFields = () => {
        setEmail('');
    }

    return (
      <>
        {
          (status === null || status === 'sending' || status === 'error')?
            <form
                className="flex h-full w-full flex-col text-[#1b8073]"
                onSubmit={(e) => handleSubmit(e)}
            >
              <div className='mt-auto mb-auto flex items-center pb-4 gap-2'>
                  <input onChange={(e)=>setEmail(e.target.value)} label="Email" value={email} required type="email" placeholder='Newsletter: Inserisci la tua mail' className='min-w-[300px] text-[16px] md:text-xl placeholder-[#1b8073] w-full outline-none border border-[#1b8073] pb-4 border-l-0 border-t-0 border-r-0 bg-transparent' />
                  <input className="hidden" type="checkbox" label="Newsletter" defaultChecked/>
                  <button type='submit'><div className='text-lg font-normal'>&rarr;</div></button>
              </div>
              <div className='mb-8 text-xs md:text-sm text-[#cccccc] font-normal'>Inserendo il tuo indirizzo e-mail, acconsenti alla nostra <a className='underline hover:text-[#1b8073]' href='/privacy-policy'>privacy</a></div>
              { status === 'error' &&
                <div className='text-red-600 text-sm'>{message}</div>
              }
            </form>
          :
          status === 'success' &&
          <div className='text-sm font-normal text-[#1b8073]'>Grazie, sei stato aggiunto alla newsletter</div>
        }
      </>
    );
};


const MailchimpForm = () => {
  const [result, setResult] = useState(null);
  const url = `https://italyhlg.us22.list-manage.com/subscribe/post?u=245c1c35111c0766e4a980d04&id=5ba848767e`;
  const onValidated = async (email) => {
    setResult("sending")
    console.log("footer", email)
    fetch(
      "https://aquamarine-halva-e2e241.netlify.app/api/subscribe-user",
      {
        method: "OPTIONS",
        headers: {
        "Content-Type": "application/json",
        },
            body: JSON.stringify({
            "email": email,
            "newsletter": "Newsletter",
        }),
        }
      )
      .then((res)=>{
        if(res.status===200) {
          setResult("success");
        } else {
          setResult("error");
        }
      })
      .catch((error)=>{
        setResult("error");
      })
  }
  return (
      <div className="mc__form-container">
          <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                  <CustomForm
                      status={result}
                      message={message}
                      // onValidated={formData => subscribe(formData)}
                      onValidated={onValidated}
                  />
              )}
          />
      </div>

  )
}

export default MailchimpForm;
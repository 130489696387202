import React, { ReactNode, useEffect, useState } from "react";
import "aos/dist/aos.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../index.css";

import Footer from "../components/Footer";
import SimpleHeader from "../components/SimpleHeader";
import MobileHeader from "../components/MobileHeader";
import MobileMenu from "../components/MobileMenu";
import {Block, Inline, BLOCKS, MARKS } from '@contentful/rich-text-types';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'

interface PolicyProps {
  policyType: string
}

const  Policy:React.FC<PolicyProps> = (props) => {
  const { policyType } = props;
  const [ content, setContent ] = useState<any>();
  const CONTENTFUL_SPACE_ID = "qitjy5hyl5up";
  const CONTENTFUL_ACCESS_TOKEN = "TKo1zCQL_v1NQmGcRQXQVqayvDSqukrdCeRlbpEedyM";
  const POLICY_ENTRY_ID = (policyType === 'privacy-policy') ? "59k0ySsxiAULkOzjw6DtcG" : "1CRiMZeUaVvNTpPzx9WyYX";
  const contentfulPolicy = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/entries/${POLICY_ENTRY_ID}?access_token=${CONTENTFUL_ACCESS_TOKEN}`;
  
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text:ReactNode) => <div className="font-bold block mt-16">{text}</div>,
    },
    preserveWhitespace: true,
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node:Block|Inline, children:ReactNode) => <p className="font-normal">{children}</p>,
      [BLOCKS.OL_LIST]: (node:Block|Inline, children:ReactNode) => <ol className="list-decimal ps-6 pt-2 pb-6">{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node:Block|Inline, children:ReactNode) => <li className="pt-4">{children}</li>,
      [BLOCKS.UL_LIST]: (node:Block|Inline, children:ReactNode) => <ul className="list-disc ps-6 pt-2 pb-6">{children}</ul>,
    },
  };

  useEffect(()=>{
    (async () => {
        try {
          const response = await fetch(
            contentfulPolicy
          );
          const data = await response.json();
          setContent(data);
        } catch (err) {
          console.error("Error fetching suggested page title:", err);
        }
    })();
  },[])

  return (
    <>
      <SimpleHeader/>
      <MobileHeader/>
      <section>
        <div className="h-[500px] bg-[#00a695] pt-[180px]  flex justify-center">
          <h1 className="text-5xl font-bold text-white mb-12 md:hidden">{content?.fields.title}</h1>
        </div>
      </section>
      <section className="relative">
        <div className="bg-white h-full flex">
          <div className="hidden md:block w-[35%] h-full"></div>
          <div className="w-full md:w-[65%] h-full bg-white mt-[-150px] md:z-100 px-[120px] pt-[100px] pb-[200px] z-100">
            <h1 className="text-5xl font-bold text-[#0c2957] mb-12 hidden md:block">{content?.fields.title}</h1>
            <div className="mb-16">
              <strong>Informativa sul trattamento dei dati personali </strong>
              <div>Artt. 13 e 14 del Regolamento 2016/679/UE (nel seguito anche “GDPR”)</div>
            </div>
            <div>
              <div>
                {
                  documentToReactComponents(content?.fields.text, options)
                }
              </div>
            </div>
          </div>
        </div>
        <MobileMenu/>
      </section>
      <Footer/>
    </>
  );
}

export default Policy;

import React, { useEffect, useState } from 'react'

import ReactCountryFlag from "react-country-flag"
import Footer from '../components/Footer'
import Header from '../components/Header';
import MobileHeader from '../components/MobileHeader';

function Ipls() {
    const [ iplsPage, setIplsPage ] = useState<any>()
    const [ sugPage, setSugPage ] = useState<any>()
    const [ iplsMembers, setIplsMembers ] = useState<any>()
    const [ iplsMemberItems, setIplsMemberItems ] = useState<any>()
    const [ isHovered, setIsHovered ] = useState(false)
    const [ hoveredIndex,  setHoveredIndex ] = useState(-1)

    const CONTENTFUL_SPACE_ID = "qitjy5hyl5up"
    const CONTENTFUL_ACCESS_TOKEN = "TKo1zCQL_v1NQmGcRQXQVqayvDSqukrdCeRlbpEedyM"
    const contentfulEndpointIpls = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries/5r3tXS77Th8uabQCltKVJ5?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=ipls`;
    const contentfulEndpointIplsMembers = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=iplsMember`;

    const handleMouseEnter = (idx:number) => {
        setIsHovered(true)
        setHoveredIndex(idx)
    }

    const handleMouseLeave = (idx:number) => {
        setIsHovered(false)
        setHoveredIndex(-1)
    }

    useEffect(()=>{
        (async () => {
            const res = await fetch(contentfulEndpointIpls)
            const ipls_page = await res.json()
            setIplsPage(ipls_page)
            const contentfulEndpointSugPage = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries/${ipls_page.fields.suggestedPage.sys.id}?access_token=${CONTENTFUL_ACCESS_TOKEN}`;
            const res0 = await fetch(contentfulEndpointSugPage)
            const suggested_page = await res0.json()
            setSugPage(suggested_page)
            const res1 = await fetch(contentfulEndpointIplsMembers)
            const ipls_members = await res1.json()
            setIplsMembers(ipls_members)
            setIplsMemberItems(ipls_members.items.sort((item_a:any, item_b:any)=>{
                return item_a.fields.order-item_b.fields.order;
            }))
        })()
    },[])

    return (
        <>
            <MobileHeader/>
            <div className='bg-[#221d44] pt-10 ps-10 md:ps-20'>
                <div className='w-full pr-8 md:pr-0 md:w-2/5 text-5xl leading-[3.5rem] text-[#80a7b6] mt-64'>
                    {iplsPage?.fields.summary.content[0].content[0].value}
                </div>
                <div className='mt-16 me-16 md:me-36 grid grid-cols-1 md:grid-cols-5'>
                    <div className='col-span-1 md:col-span-2 text-white text-xl font-bold'>IPLS</div>
                    <div className='col-span-1 md:col-span-3 text-white'>
                        {iplsPage?.fields.body.content.map((desc:any, index:number)=>{
                            return (
                            <p key={index} className="mt-6 text-md font-[600]">
                                {
                                desc?.content.map((p:any, indexp:number)=>{
                                    return (
                                    <span className={`${(p.marks && p.marks[0]?.type === 'bold') ? 'font-bold' : 'font-normal'} ${(p.marks?.length && p.marks[0]?.type === 'italic') ? 'italic' : ''}`}>{p.value}</span>
                                    )
                                })
                                }
                            </p>
                            )
                        })
                        }
                        <p className='mt-12 text-[#63e4d7]'>Membri del network</p>
                        <div className='mt-12 grid grid-cols-1 md:grid-cols-2 gap-10'>
                            {
                                iplsMemberItems?.map((mem:any, idx:number)=>{
                                    return (
                                        <a href={mem.fields.link} target='_blank' className={`col-span-1 flex gap-4 ${(!isHovered || hoveredIndex==idx)?'brightness-100': 'brightness-50'} cursor-pointer`}>
                                            <img onMouseEnter={()=>handleMouseEnter(idx)} onMouseLeave={()=>handleMouseLeave(idx)} style={{width:'80%'}} src={iplsMembers?.includes.Asset.filter((asset:any)=>{return asset.sys.id==mem.fields.logo.sys.id})[0].fields.file.url} alt="" />
                                            <ReactCountryFlag style={{width:'1.6rem',height:'1rem'}} countryCode={mem.fields.country} svg />           
                                        </a>
                                    )
                                })
                            }
                        </div>
                        <div className='mt-40 mb-32 w-full flex justify-end ml-0 md:ml-auto mr-[-30px]'>
                            <a href='' className='text-2xl md:text-3xl text-left md:text-right text-white'>Continua con <span className='font-bold'> {sugPage?.fields.title} &rarr;</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <Header/>
            <Footer/>
        </>
    )
}

export default Ipls
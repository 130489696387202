import React, { useContext, ReactNode, useEffect, useState } from "react";
import {Block, Inline, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import Footer from "../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { getDatabase, ref, get, set, child } from "firebase/database";
import { AuthContext } from "../context/UserContext";

import config from '../config/index.json';
import GalleryPng from '../images/gallery.png'
import ArrowUpRightPng from '../images/arrow-up-right.png'
import PdfPng from '../images/pdf1.png'
import { url } from "inspector";

const CONTENTFUL_SPACE_ID = "qitjy5hyl5up"
const CONTENTFUL_ACCESS_TOKEN = "TKo1zCQL_v1NQmGcRQXQVqayvDSqukrdCeRlbpEedyM"  
const contentfulEndpointDocuments = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries/`;
const contentfulEndpointAssets = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/assets/`;

function ReservedAreaPrivateDocs() {
  const { images } = config
  const { logo } = images
  const { logOut, user, userInfo } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [ documents, setDocuments ] = useState<any>([]);
  const [ userName, setUserName] = useState('');
  const {doc_item} = location.state;
  let partDate = doc_item.fields.date.split('-');
  let formattedDate = `${partDate[1]}/${partDate[2]}/${partDate[0]}`;
  const options = {
    renderMark: {
      // [MARKS.BOLD]: (text:ReactNode) => <div className="font-bold mt-16 inline">{text}</div>,
    },
    preserveWhitespace: true,
    renderNode: {
      [INLINES.HYPERLINK]: (node:Block|Inline, children:ReactNode) => {
        console.log('node', node);
        const url = node.data?.uri;
        const isYoutube = isYouTubeUrl(url)
        return (
          <>
          {isYoutube ?
            <iframe width="420" height="315" src={url}/>
            :
            <a target="__blank" href={url} className="text-[#0c2957] underline">{children}</a>
          }
          </>
        )
        },
      [BLOCKS.PARAGRAPH]: (node:Block|Inline, children:ReactNode) => <p className="font-semibold mt-4">{children}</p>,
      [BLOCKS.OL_LIST]: (node:Block|Inline, children:ReactNode) => <ol className="font-semibold ps-8 mt-6 list-disc">{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node:Block|Inline, children:ReactNode) => <li className="mb-4">{children}</li>,
      [BLOCKS.UL_LIST]: (node:Block|Inline, children:ReactNode) => <ul className="font-semibold list-disc ps-6 pt-2 pb-6">{children}</ul>,
    },
  };
  useEffect(()=>{
    const db = getDatabase();
    get(ref(db, "users/" + user.uid)).then((userInfo)=>{
      const userData = userInfo.val();
      const uName = `${userData?.name ?? ''} ${userData?.surname ?? ''}`;
      setUserName(uName);
    })
    .catch((error:any)=>{
      console.error(error)
    });
    
    let documents:any = [];
    const fetchDocuments = async (doc:any) => {
      try {
        const res = await fetch(`${contentfulEndpointDocuments}${doc.sys.id}?access_token=${CONTENTFUL_ACCESS_TOKEN}`);
        const document = await res.json();
        return document;
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };
  
    (async () => {
      const docs = doc_item?.fields?.documents || []
      for (const doc of docs) {
        const document = await fetchDocuments(doc);
        documents = [...documents, document];
      }
      setDocuments(documents);
    })();

    window.scrollTo(0,0)
  },[])

  function isYouTubeUrl(url:string) {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([a-zA-Z0-9_-]+)/;
    return youtubeRegex.test(url);
  }

  const onClickLogout = () => {
    logOut();
    navigate('/')
  }

  const handleClickLogo = () => {
    navigate('/');
  }

  const handleSelectDoc = (fileId:any) => {
    (async () => {
      const res = await fetch(`${contentfulEndpointAssets}${fileId}?access_token=${CONTENTFUL_ACCESS_TOKEN}`);
      const fileUrl = await res.json();
      const downloadUrl = fileUrl.fields?.file?.url;
      // window.open(fileUrl.fields?.file?.url,'download');
      const fileName = downloadUrl.substring(downloadUrl.lastIndexOf('/') + 1);
      fetch(downloadUrl).then((response) => {
        response.blob().then((blob) => {
            const fileURL =
                window.URL.createObjectURL(blob);
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = fileName;
            alink.click();
        });
    });

    })()
  }
  
  const username = `${userInfo?.name ?? ''} ${userInfo?.surname ?? ''}`
  return (
    <div className="bg-[#effbff]">
      <section
        id="hero"
        className="bg-hero brightness-100 bg-no-repeat w-3/5 bg-center bg-cover pt-80 pb-6 h-[40vh]"
      >
        <div
          id="header"
          className="absolute w-full top-0 z-20 pt-4 md:pt-16 pb-4 px-4 md:px-16"
        >
          <div className="flex justify-center md:justify-between flex-col-reverse	md:flex-row">
            <div className="flex items-left md:items-center">
              <img
                src={logo}
                className="mt-16 md:my-4 w-40 block mr-auto ml-0 md:mx-auto hover:cursor-pointer"
                alt="logo"
                onClick={handleClickLogo}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <SimpleHeader/> */}
      <section>
        <div className="pb-[200px]">
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className="col-span-2 pt-[100px] pl-[60px] md:pr-[20px] lg:pr-[60px]">
              <h1 className="text-lg font-bold mb-2">{username}</h1>
              <hr className="color-[#00a695] border border-[#00a695] w-4/5" />
              <a href="/documenti">
                <div className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a695] hover:underline hover:cursor-pointer">
                  Archivio documenti riservati
                </div>
              </a>
              <a href="/elenco-associati">
                <div className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a594] hover:underline">
                  Elenco associati
                </div>
              </a>
              <a href="/profilo">
                <div className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a695] hover:underline hover:cursor-pointer">
                 Il mio profilo
                </div>
              </a>
              <div onClick={()=>onClickLogout()} className="text-lg text-[#242424] font-semibold mt-2 hover:text-[#00a695] hover:underline hover:cursor-pointer">
                Esci
              </div>
              <hr className="color-[#00a695] border border-[#00a695] w-4/5 mt-80" />
              <div className="text-lg text-[#242424] font-semibold mt-2">
                Hai bisogno di aiuto?
              </div>
              <a href="mailto:info@italyhlg.it" className="text-lg font-semibold text-[#00a695] mt-2 underline">
                info@italyhlg.it
              </a>
            </div>

            <div id="" className="col-span-3 z-10 mt-[-120px]">
              <div id="form" className="z-50 bg-[#effbff] p-24">
                <div className="text-2xl text-[#0c2957] font-[700]">
                  <h1 className="mb-6">Archivio  documenti</h1>
                  <div className="text-[#00a695] text-sm mt-16 font-semibold">{formattedDate}</div>
                  <div className="text-[#0c2957] text-4xl font-bold py-4">{doc_item.fields.name}</div>
                  <div className="text-[18px] leading-[2rem]">
                  {
                    documentToReactComponents(doc_item.fields?.description, options)
                  }
                    {/* <div className="font-semibold mt-4">{doc_item.fields?.description?.content[0]?.content[0]?.value}</div>
                    <div className="font-semibold mt-4">{doc_item.fields?.description?.content[1]?.content[0]?.value}</div>
                    <ol className="font-semibold ps-8 mt-6 list-disc">
                      {doc_item.fields?.description.content.map((desc:any)=>{
                        return (
                          desc?.content[0]?.value &&
                          <li className="mb-4">
                            {desc?.content.map((content:any)=>{
                              return (
                                <>
                                  {
                                    content?.nodeType==='hyperlink' ? 
                                      <a className="underline" href={content?.data?.uri}>{content?.content[0]?.value}</a>
                                      :
                                      <>{content.value}</>
                                  }
                                </>
                              )
                            })}
                          </li>
                          // desc?.content[0]?.value && <li className="mb-4">{desc?.content[0]?.value}</li>
                        )}
                        )}
                    </ol> */}
                  </div>
                  <div className="mt-12 pe-20">
                    <a href={doc_item.fields?.gallery} target="_blank" className="flex items-center w-full p-4 text-sm bg-white text-[#1464f6] border border-[#00ffff] hover:border-[#000000] rounded-xl font-bold">
                        <img src={GalleryPng} className="w-[20px] h-[16px]" alt="icon" />
                        <span className="ms-2 me-1">Gallery</span>
                        <img src={ArrowUpRightPng} className="flex w-[10px] h-[10px] pt-auto" alt="icon1" />
                    </a>
                    <div className="text-xs font-bold text-black mt-8">Documenti</div>
                    {documents.map((doc:any)=>{
                      return (
                        <div onClick={()=>handleSelectDoc(doc.fields.file.sys.id)} className="hover:cursor-pointer flex p-4 mt-6 bg-white border border-[#00ffff] hover:border-[#000000] rounded-xl">
                          <img src={PdfPng} className="w-[20px] h-[20px]" alt="icon-pdf" />
                          <div className="ms-2">
                            <h1 className="text-sm font-bold">{doc.fields.name}<span className="font-normal">-{`${doc.fields.extension}-${doc.fields.fileSize}`}</span></h1>
                            <div className="text-sm font-semibold py-4">{doc.fields.description.content[0].content[0].value}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default ReservedAreaPrivateDocs;

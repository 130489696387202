import React, { useContext } from 'react'
import { AuthContext } from '../context/UserContext';
import { useNavigate } from 'react-router';

interface ReservedSideMenuProps {
  selectedMenuIndex?: Number,
  selectedMenu?: string
}

const ReservedSideMenu:React.FC<ReservedSideMenuProps> = (props) => {
  const { logOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const { selectedMenuIndex } = props;
  
  const onClickLogout = () => {
    logOut();
    navigate('/')
  }
  return (
    <div>
        <div className="md:hidden block col-span-2 text-[#00a695] font-normal text-xl py-4">
            <a href="/documenti">
            <div className={`mt-2 ${selectedMenuIndex!==0 ? 'underline cursor-pointer hover:font-semibold':'font-semibold'}`}>
                Archivio documenti riservati
            </div>
            </a>
            <a href="/elenco-associati">
            <div className={`mt-2 ${selectedMenuIndex!==1 ? 'underline  cursor-pointer hover:font-semibold':'font-semibold'}`}>
                Elenco associati
            </div>
            </a>
            <div className={`mt-2 ${selectedMenuIndex!==2 ? 'underline  cursor-pointer hover:font-semibold':'font-semibold'}`}>
            Il mio profilo
            </div>
            <div onClick={()=>onClickLogout()} className={`mt-2 'underline underline  cursor-pointer hover:font-semibold`}>
            Esci
            </div>
        </div>
    </div>
  )
}

export default ReservedSideMenu